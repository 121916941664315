import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import logo from '../../../Components/Image/HRVerse@2x.png';
// import './Login.css';
import { Link } from 'react-router-dom';
import loginsymbol from '../../../Components/Image/Group 6110@2x.png'


export default function BasicTextFields() {
    return (
        <>

            <div className='login-bg'>
                {/* <h1>iuhih</h1> */}

                <img className='formposition' src={logo} />
                <p className='forgot-password-tittle'> Please enter your email, a reset password link will be sent to you. </p>


                <form action="/action_page.php">




                    <input className='loginforminput' type="text" id="fname" name="fnae" placeholder='Your email ' />

                    <br />

                    {/* <input className='loginforminput' type="Password-" id="fname" name="fnme" placeholder='Your Password' />

                    <br /> */}

                    <input className='login-submit-button' type="submit" value="SUBMIT" />


                </form>


                {/* <h1 className='forgot' >
                    <Link to="/">
                        Forgot your password?
                    </Link>

                </h1> */}
                {/* <p className='sig-up-tittle'>Don't have an account?<Link to="/"> Sign up     </Link> </p> */}





                <img src={loginsymbol} className="reseatpasswordsymboll" />

            </div>


        </>



    );
}
