import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import customerimage from '../../../Components/Image/customer.svg'
import Container from '@mui/material/Container';
import Counter from '../../Home/Counter/Counter';
import Counter2 from '../Counts2/Counter/Counter2';
import './CustomerResponsive.css';



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function FullWidthGrid() {
    return (


        <>



            <React.Fragment >
                <CssBaseline />
                <Container maxWidth="sm" style={{ margin: "0px" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <div style={{ display: "flex" }}>
                                <img style={{ width: "40%", height: "auto", objectFit: "contain" }} src={customerimage} className="customericon " />
                                <div style={{ color: '#707070', fontSize: "14px" }}>
                                    <Counter />
                                    <h1 style={{ textAlign: "right", marginTop: "-5%", fontFamily: "lato", position: "absolute" }}>Customers</h1>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{ display: "flex" }}>
                                <img style={{ width: "40%", height: "auto", objectFit: "contain" }} src={customerimage} className="customericon " />
                                <div style={{ color: "#707070", fontSize: "14px" }}>
                                    <Counter2 />
                                    <h1 style={{ textAlign: "right", marginTop: "-5%", color: "#707070", position: "absolute", fontFamily: "lato" }}>Daily Logins</h1>

                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </React.Fragment>

            {/* 

            <div sx={{ flexGrow: 1 }}>
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <div style={{ margin: "auto", textAlign: "", marginLeft: "0%" }}>
                                <img style={{ width: "40%", height: "auto" }} src={customerimage} className="customericon " />

                                <h1 className='StartFont ' style={{ position: "absolute", marginLeft: "15%", fontSize: "10px", }} >
                                    <Counter />
                                    <h1 style={{ position: "absolute", fontSize: "10px" }} className="custumer-respomsive">Customers</h1>
                                </h1>
                            </div>
                        </Grid>
                        <Grid item xs={6} >
                            <div style={{ marginLeft: "0%" }}>
                                <img src={customerimage} className="customericon " style={{ width: "40%", height: "auto" }} />
                                <span className='already' style={{ color: "#606062", fontSize: "10px", position: "absolute", }}>
                                    <Counter2 />
                                    <h1 style={{ position: "absolute", fontSize: "10px", textAlign: "justify" }} className="custumer-respomsive">Daily Logins  </h1>

                                </span>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div> */}

        </>

    );
}


