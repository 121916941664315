import { useState } from 'react'
import './Formvalidatin.css';

function App() {

    return (
        <nav className='navbarsres'>
            <div class="logo">
                <h1>Earavind</h1>
            </div>
            {/* <button class="btn-toggle">
                <span></span>
                <span></span>
                <span></span>
            </button> */}
            <ul>
                <li><a href="#">Home</a></li>
                <li><a href="#">About</a></li>
                <li><a href="#">Contact</a></li>
                <li><a href="#">Services</a></li>
                <li><a href="#">Gallery</a></li>
                <li><a href="#">Gallery</a></li>
                <li><a href="#">Gallery</a></li>

            </ul>
        </nav>
    );
}

export default App;
