import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import './Services.css';
import payrollserviceimage from '../../Components/Image/payroll-services@2x.png';
import Startforfree from '../Home/Startforfree/Startforfree';
import Customer from '../Home/Customer/Customer';
import Footer from "../Footer/Footer";
import Copyright from "../Copyright/Copyright/Copyright";
import StartupSection from '../Home/StartupSection/StartupSection';
import Servicescard from './Servicescard/Servicescard';
import Sidebar from '../../Components/Sidebar';
import Symbollservices from '../../Components/Image/Group 6110@2x.png';
import Positionfix from '../../Demopages/positionfix/Positionfix';




const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function FullWidthGrid() {
    return (

        <><Sidebar />





            <Container>
                <Box sx={{ flexGrow: 1 }} style={{ paddingTop: "120px" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={7} sm={6}>
                            {/* <Item>xs=6 md=8</Item> */}

                            <div className='Payrollfont'>
                                <img width={120} height="auto" src={Symbollservices} className="symboll-img-sevices" />
                                <h1 className='Servicesh1'> Payroll Serviceś</h1>
                                <br />
                                <p className='Services-p'> In publishing and graphic design, Lorem ipsum is a  In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.</p>



                            </div>
                        </Grid>
                        <Grid item xs={12} md={5} sm={6}>
                            {/* <Item>xs=6 md=4</Item> */}
                            <img src={payrollserviceimage} style={{ width: "100%", height: "100%", objectFit: "contain" }} />

                        </Grid>

                    </Grid>
                </Box>

            </Container>



            <Servicescard />

            <StartupSection />


            <br />
            <br />

            {/* <div style={{ paddingRight: "200px", paddingLeft: "200px" }}  >  <Startforfree /> </div> */}

            {/* <div className='Startforfreecontainer'  >  <Startforfree /> </div> */}
            <Positionfix />



            <br />
            <Container className=''>
                {/* <hr className='horizontalline' /> */}
            </Container>
            <Customer />


            {/* <Container className=''> */}
                <hr className=' horizontallinebottom' />
            {/* </Container> */}

            <Footer />

            {/* <Container className=''> */}
                <hr className='horizontallinebottom' />
            {/* </Container> */}

            <Copyright />

        </>


    );
}
