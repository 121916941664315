import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import DirectionsBoatFilledIcon from '@mui/icons-material/DirectionsBoatFilled';
import "./Industry.css";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';


import ApartmentIcon from '@mui/icons-material/Apartment';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import SailingIcon from '@mui/icons-material/Sailing';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import AgricultureOutlinedIcon from '@mui/icons-material/AgricultureOutlined';
import FactoryOutlinedIcon from '@mui/icons-material/FactoryOutlined';
import DvrOutlinedIcon from '@mui/icons-material/DvrOutlined';

// import Start from '../../../Components/Image/Start.png';
import Start from '../../../Components/Image/Startups (1).svg';
import Manufacturing from '../../../Components/Image/Manufacturing.svg';
import Software from '../../../Components/Image/IT & Software.svg';
import Retail from '../../../Components/Image/Retail.svg';
import Agriculture from '../../../Components/Image/Agriculture.svg';
import Construction from '../../../Components/Image/Construction.svg';
import Shipping from '../../../Components/Image/Shipping & Marine.svg';
import School from '../../../Components/Image/School.svg';
import Salon from '../../../Components/Image/Salon & Spa.svg';

import Logistics from '../../../Components/Image/Logistics.svg';
import Beverage from '../../../Components/Image/Food & beverage.svg';
import commerce from '../../../Components/Image/E-Commerce.svg';







const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function FullWidthGrid() {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={4} style={{ padding: "20px" }} >

                <Grid item xs={4} sm={4} lg={4} xl={4} md={4} style={{ textAlign: "center" }}>

                    <div className="myDIV hovejump">
                        {/* <DirectionsBoatFilledIcon className='iconsize myDIV' style={{ fontSize: "80px" }} /> */}
                        {/* <img src={Start} className="myDIV iconsizefont iconsize" style={{ margin: "auto", padding: "auto", width: "100px", height: "100px", textAlign: "center" }} /> */}

                        <img src={Start} className="myDIV iconsize" />

                        <br /><span className='iconsizefont'>   Water Soluble</span>
                    </div>

                    <div className="hide" style={{ marginLeft: '29%', color: "white", marginTop: "-40px" }}>.</div></Grid>


                <Grid item xs={4} sm={4} lg={4} xl={4} md={4} >

                    <div className="myDIV hovejump">
                        {/* <FactoryOutlinedIcon className='iconsize myDIV' style={{ fontSize: "80px" }} /> */}

                        <img src={Manufacturing} className="myDIV iconsize" />


                        <br /><span className='iconsizefont'> Spindle Oil</span>
                    </div>

                    <div className="hide" style={{ marginLeft: '29%', color: "white", marginTop: "-40px" }}>.</div></Grid>



                <Grid item xs={4} sm={4} lg={4} xl={4} md={4} style={{ textAlign: "center" }}>

                    <div className="myDIV hovejump">

                        {/* <DvrOutlinedIcon className='iconsize myDIV' style={{ fontSize: "80px" }} /> */}
                        <img src={Software} className="myDIV iconsize" />


                        <br /><span className='iconsizefont'> Gear Oil </span>
                    </div>

                    <div className="hide" style={{ marginLeft: '29%', color: "white", marginTop: "-40px" }}>.</div>
                </Grid>
                <Grid item xs={4} sm={4} lg={4} xl={4} md={4} style={{ textAlign: "center" }}>

                    <div className="myDIV hovejump">
                        {/* <AddBusinessOutlinedIcon className='iconsize myDIV' style={{ fontSize: "80px" }} /> */}
                        <img src={Retail} className="myDIV iconsize" />

                        <br /><span className='iconsizefont'> Quenching Oil</span>
                    </div>

                    <div className="hide" style={{ marginLeft: '29%', color: "white", marginTop: "-40px" }}>.</div></Grid>

                <Grid item xs={4} sm={4} lg={4} xl={4} md={4} style={{ textAlign: "center" }}>

                    <div className="myDIV hovejump">
                        {/* <AgricultureOutlinedIcon className='iconsize myDIV' style={{ fontSize: "80px" }} /> */}
                        <img src={Agriculture} className="myDIV iconsize" />

                        <br /><span className='iconsizefont'> Heat Transfer Fluids Oils</span>
                    </div>

                    <div className="hide" style={{ marginLeft: '29%', color: "white", marginTop: "-40px" }}>.</div>
                </Grid>


                <Grid item xs={4} sm={4} lg={4} xl={4} md={4} style={{ textAlign: "center" }}>

                    <div className="myDIV hovejump">
                        {/* <ApartmentIcon className='iconsize myDIV' style={{ fontSize: "80px" }} /> */}
                        <img src={Construction} className="myDIV iconsize" />

                        <br /><span className='iconsizefont'> Tapping Oil</span>
                    </div>

                    <div className="hide" style={{ marginLeft: '29%', color: "white", marginTop: "-40px" }}>.</div></Grid>

                {/* $$$$$$$$$$$$$$ */}

               

            </Grid>
        </Box>
    );
}


