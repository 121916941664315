import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import Grid from '@mui/material/Grid';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import Container from '@mui/material/Container';
import './Nutshellscroll.css';

// import Demo from './Demo.json';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
// import Startupimage from "../Components/Image/Group 856@2x.png"
// import './Demo.css';

// import Startupimage from '../Components/Image/'

// import nut from '../Components/Image/Group122@2x30.png';


// import nut from '../Components/Image/Start.png'; Asset Managementsmall.svg




const AutoPlaySwipeableViews = autoPlay(SwipeableViews);


const images = [
    {


        "imgPath": require("../../../Components/Image/cleaning.jpeg")

    },

    {
       
        
        "imgPath": require("../../../Components/Image/slider1.jpeg")
    },
    {
        
        "imgPath": require("../../../Components/Image/turbine-oil.jpeg")
    },
    {
        
        "imgPath": require("../../../Components/Image/slider5.png")
    },



    // {
    //     label: 'Advancement',
    //     "paragraph": "1Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce porta venenatis mi sit amet venenatis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce porta venenatis mi sit amet venenatis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce porta venenatis mi sit amet venenatis.",
    //     "imgPath": require("../../../Components/Image/Group 122@2x30.png")
    // },

    // {
    //     label: ' Resignation',
    //     "paragraph": "1Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce porta venenatis mi sit amet venenatis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce porta venenatis mi sit amet venenatis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce porta venenatis mi sit amet venenatis.",
    //     "imgPath": require("../../../Components/Image/Group 1082@2x.png")
    // },



    // {
    //     label: ' Post Exit',
    //     "paragraph": "1Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce porta venenatis mi sit amet venenatis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce porta venenatis mi sit amet venenatis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce porta venenatis mi sit amet venenatis.",
    //     "imgPath": require("../../../Components/Image/Group 890@2x.png")
    // },



];

function SwipeableTextMobileStepper() {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = images.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

    return (


        <>


            {/* <img src={Startupimage} style={{ width: "100%", height: "auto", objectFit: "contain" }} /> */}


            <Box sx={{ maxWidth: "100%", flexGrow: 1 }}>
                <Paper
                    square
                    elevation={0}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: 50,
                        pl: 2,
                        bgcolor: 'background.default',
                    }}
                >


                    {/* <Typography>{images[activeStep].label}</Typography> */}
                </Paper>
                {/* <AutoPlaySwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                >
                    {images.map((step, index) => (
                        <div >
                            {Math.abs(activeStep - index) <= 2 ? (
                                <Box
                                    component="img"
                                    sx={{
                                        height: 255,
                                        display: 'block',

                                        maxWidth: "100%",
                                        overflow: 'hidden',
                                        width: '100%',
                                    }}
                                    src={step.imgPath}
                                    alt={step.label}
                                />



                            ) : null}
                        </div>
                    ))}
                </AutoPlaySwipeableViews> */}

                {/* ################### */}
                <Container>
                    <AutoPlaySwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                        enableMouseEvents
                    >
                        {images.map((step, index) => (
                            <div key={step.label}>
                                {Math.abs(activeStep - index) <= 2 ? (
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Grid container spacing={2}>

                                            <Grid item xs={12} md={6} sm={6}>
                                                <img style={{ width: "80%", height: "90%", objectFit: "contain" }}
                                                    component="img"
                                                    sx={{
                                                        // height: 255,
                                                        display: 'block',

                                                        // maxWidth: "100%",
                                                        // overflow: 'hidden',
                                                        // width: '100%',
                                                    }}


                                                    src={step.imgPath} />

                                            </Grid>

                                            <Grid item xs={12} md={6} sm={6}>
                                                <div className=''>
                                                    <h1 className='heading-nutshel'>{images[activeStep].label}</h1>

                                                </div>


                                                <div>
                                                    <p className='hiringparagraph'>{images[activeStep].paragraph}</p>


                                                </div>





                                            </Grid>




                                        </Grid>
                                    </Box>
                                ) : null}
                            </div>
                        ))}

                    </AutoPlaySwipeableViews>

                </Container>

                {/* @@@@@@@@@@@@@@@@@@@@@@ */}


                {/* <div className='mobilesteper' style={{ backgroundColor: "red" }}> */}
                <div className='scrolling_button'>

                    <MobileStepper

                        steps={maxSteps}
                        position="static"
                        activeStep={activeStep}
                        nextButton={
                            <Button
                                size="small"
                                onClick={handleNext}
                                disabled={activeStep === maxSteps - 1}
                            >
                                {/* Next */}
                                {theme.direction === 'rtl' ? (
                                    <ArrowBackOutlinedIcon style={{ fontSize: "40px", color: "#1C6BFE" }} />
                                ) : (
                                    <ArrowForwardOutlinedIcon style={{ fontSize: "40px", color: "#1C6BFE" }} />
                                )}
                            </Button>
                        }


                        backButton={
                            <Button




                                size="small" onClick={handleBack} disabled={activeStep === 0}>
                                {theme.direction === 'rtl' ? (
                                    <ArrowForwardOutlinedIcon style={{ fontSize: "40px", color: "#1C6BFE" }} />
                                ) : (
                                    <ArrowBackOutlinedIcon style={{ fontSize: "40px", color: "#1C6BFE" }} />
                                )}
                                {/* Back */}
                            </Button>
                        }
                    />
                </div>

                {/* </div> */}


            </Box>
        </>
    );
}

export default SwipeableTextMobileStepper;



