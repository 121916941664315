import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TypingEffect from '../Home/TypingEffect';
import { padding } from '@mui/system';
import Hoverjumpdemo from '../../Demopages/hoverjumpdemo/Hoverjumpdemo';
// import "./TypingEffect.css";
import BookLiveForm from '../Home/BookLiveForm/BookLiveForm';
import Logo from '../../Components/Image/HRVerse@2x.png';
import Symboll from '../../Components/Image/Group 632@2x.png';
import StartupSection from '../Home/StartupSection/StartupSection';
import NutShell from '../Home/NutShell/NutShell';
import Recrument from '../Home/Recrument/Recrument';
import Industry from "../Home/Industry/Industry";
import Startforfree from '../Home/Startforfree/Startforfree';
import Customer from "../Home/Customer/Customer";
import CustomerResponsive from "../Home/Customer/CustomerResponsive";



import Counter from '../Home/Counter/Counter';
import Footer from '../Footer/Footer';
// import Container from '@mui/material/Container';
import Copyright from '../Copyright/Copyright/Copyright'
import Sidebar from '../../Components/Sidebar';
import Nutshellscroll from '../../Pages/Home/NutShell/Nutshellscroll';
import Startforfreeold from '../Home/Startforfree/Startforfree-old';
import Positionfix from '../../Demopages/positionfix/Positionfix';
import ApplyForCredits from './ApplyForCredits/ApplyForCredits';
import StartupFeature from './StartupFeature/StartupFeature';
import Startupfirst from './Startupfirst/Startupfirst';
import FounderForm from './FounderForm/FounderForm';
import FooterMobile from '../FooterMobile/FooterMobile';
import StartupBanner from './Startupfirst/StartupBanner';



// import Customer from '../Home/Customer/Customer';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function FullWidthGrid() {
    return (

        <>
            <Sidebar />

            <div>
                {/* <Startupfirst /> */}
                <br />
                <br />
                <br />

                <div style={{ paddingBottom: "38%" }}>
                    <StartupBanner />
                </div>


            </div>


            {/* <FounderForm /> */}


            <StartupFeature />






            {/* <ApplyForCredits /> */}

            <br />
            <br />

            {/* Start for free section  */}
            <Positionfix />
            <br />



            <Container className=''>
                {/* <hr className='horizontalline' /> */}
            </Container>
            <div className='display-none-form'>
                <Customer />
            </div>
            <div className='display-show'>
                <CustomerResponsive />
            </div>



            {/* ############### */}


            {/* @@@@@@@@@@@@@@@@@@ */}


            {/* <Container className=''> */}
            {/* <hr className='horizontalline2' /> */}
            <hr className='horizontallinebottom' />

            {/* </Container> */}

            {/*             
            <Footer />
            <hr className='horizontallinebottom' />
            <Copyright /> */}

            <div className='display-none-form'>
                <Footer />
                {/* <Container className=''> */}
                <hr className='horizontallinebottom' />
                {/* </Container> */}
                <Copyright />
            </div>
            <div className='display-show'>
                <FooterMobile />
            </div>


        </>
    );
}


