import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import './Customer.css';
// import Startforfreecontent from './Startforfreecontent';
import { Link } from '@mui/material';
import Counter from "../Counter/Counter";
import Counter2 from '../Counts2/Counter/Counter2';
import customerimage from '../../../Components/Image/customer.svg'
import { margin } from '@mui/system';




const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function FullWidthGrid() {
    return (
        <>
            {/* 
            <Container>
                <Grid container spacing={2} >
                    <Grid item xs={12} md={4} sm={4} style={{ margin: "auto", paddingLeft: "20%" }}>
                        <div style={{ display: "flex", }} >
                            <img src={customerimage} className="" style={{ height: "100px", width: "115px" }} />
                            <h1><Counter />
                            </h1>
                        </div>

                    </Grid>
                    <Grid item xs={12} md={2} sm={4} style={{ textAlign: "center", paddingTop: '50px', }}>
                        <div className="vl"></div>
                    </Grid>
                    <Grid item sm={4} xs={12} md={4} style={{ margin: "auto", }}>
                        <div style={{ display: "flex", }} >

                            <img src={customerimage} className="" style={{ height: "100px", width: "115px" }} />
                            <span className=''> <Counter2 />
                            </span>
                        </div>

                    </Grid>
                </Grid>
            </Container>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
 */}






            {/* ############################################## last changes */}

            {/* <hr className='horizontalline' /> */}
            <Container className='' style={{ padding: "10px", paddingRight: "50px", paddingLeft: "50px", backgroundColor: "", borderRadius: "10px", opacity: "" }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2} style={{ fontSize: "30px" }}>
                        <Grid item xs={12} md={6} sm={4} style={{ textAlign: "center", float: "right", fontSize: "30px" }} container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-end" >
                            {/* <Startforfreecontent /> */}
                            <img src={customerimage} className="customericon " />
                            <h1 className='StartFont ' style={{ position: "absolute", marginLeft: "250px" }} > <Counter />
                                {/* <span className='spancustomer'>Customer</span> */}
                            </h1>
                        </Grid>
                        <Grid item xs={12} md={1} sm={4} style={{ textAlign: "center", paddingTop: '50px', }}>
                            <div className="vl"></div>
                        </Grid>
                        <Grid item sm={4} xs={12} md={5} style={{ textAlign: "", fontSize: "30px", }}
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-end"
                        >
                            <img src={customerimage} className="customericon" style={{ marginRight: "360px" }} />
                            <span className='already' style={{ color: "#606062", fontSize: "30px", position: "absolute", marginLeft: "40px" }}> <Counter2 />
                                {/* <span className='spancustomer'>Daily Logins </span>  */}
                            </span>
                        </Grid>
                    </Grid>
                </Box>
            </Container>


        </>
    );
}






