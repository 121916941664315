import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import './CalculatePlan.css';
import Card from '@mui/material/Card';
import pricingcardsymboll from '../../../Components/Image/Group 6330@2x.png';

import { Link } from 'react-router-dom';





class App extends React.Component {
    constructor() {
        super();
        this.state = {
            price: 0,
            discount: 0,
            total: 0,
            finalPrice: 0,
            gst: 0,
            onboarding: 0,
            baseprice: 0,
            totalpaid: 0,
            addgst: 0,
            onetime: 0,

        }
        this.handleDiscount = this.handleDiscount.bind(this);
    }

    /* update values on change */
    handleDiscount(event) {
        this.setState({
            [event.target.name]: event.target.value
        },
            () => {
                this.setState({
                    total: this.totalDiscount(),
                    finalPrice: this.getFinalPrice(),
                    gst: this.getgst(),
                    onboarding: this.getonboarding(),
                    baseprice: this.getbaseprice(),
                    totalpaid: this.gettotalpaid(),
                    addgst: this.getaddgst(),
                    onetime: this.getonetime(),

                })
            });
    }

    /* calculate and return total discount */
    totalDiscount() {
        return (Math.abs((((1 - (this.state.discount / 100))) - 1) * 100)).toFixed(2);
    }

    getFinalPrice() {
        return (this.state.price - (this.state.price * this.totalDiscount() / 100)).toFixed(2);
    }


    getbaseprice() {
        return (this.state.price * 0.025 * 12).toFixed(2);
    }

    getgst() {
        // return (this.state.price - (this.state.price * (100 / (100 + 18)))).toFixed(2);
        // return (((this.state.price * 18) / 100).toFixed(2));
        return (((this.getaddgst() * 18) / 100).toFixed(2));






    }





    // getgst() {
    //     return (Math.abs(this.gettotalpaid() - (this.gettotalpaid() * (100 / (100 + 18))))).toFixed(2);
    // }


    getonboarding() {

        return (Math.abs(this.state.price * 100)).toFixed(2);

    }


    getaddgst() {
        return (Math.abs((+this.getbaseprice()) + (+this.getonboarding()))).toFixed(2);
    }




    gettotalpaid() {

        // return (this.state.price + this.getonboarding).toFixed(2);
        // return (Math.abs((+this.state.price) + (+this.getonboarding() + (+this.getgst())))).toFixed(2);
        return (Math.abs((+this.getbaseprice()) + (+this.getonboarding() + (+this.getgst())))).toFixed(2);


    }
    getonetime() {
        return (Math.abs((+this.getonboarding()) + (+this.getgst()))).toFixed(2);
    }


    // getgst() {
    // return (Math.abs(this.gettotalpaid() - (this.gettotalpaid() * (100 / (100 + 18))))).toFixed(2);


    // return ((this.gettotalpaid()) - ((this.gettotalpaid()) * ((100 / (100 + 18))))).toFixed(2);

    // return (this.state.totalpaid - (this.state.totalpaid * (100 / (100 + 18)))).toFixed(2);


    // }


    // totalDiscount() {
    //     return (Math.abs((((1 - (this.state.discount / 100))) - 1) * 100)).toFixed(2);
    // }



    // const Item = styled(Paper)(({ theme }) => ({
    //     backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    //     ...theme.typography.body2,
    //     padding: theme.spacing(1),
    //     textAlign: 'center',
    //     color: theme.palette.text.secondary,
    // }));

    // export default function FullWidthGrid() {
    //     return (
    render() {
        return (
            <>


                <Box sx={{ flexGrow: 1 }}>

                    <div className='calculate-bg'>
                        <div  >
                            <h1 className='calculate-h1' >Calculate Your Plan
                                <img className='calculate-pricingcardsymboll' width={10} src={pricingcardsymboll} />



                            </h1>

                        </div>
                        <br />
                        <p className='calculate-p'>Enter the number of employees that will be using HRVERSE</p>
                        <input className='calculate-input'
                            // placeholder='100'
                            name="price"
                            value={this.state.price}
                            onChange={this.handleDiscount}
                            type="number" />
                    </div>



                </Box>


                <br />
                <div className='calculate-button'>
                    <Link to="/Pricing">
                        <button style={{ backgroundColor: "#E7F0FF", color: "#1C6BFE" }} className='calculate-India'>India</button></Link>


                    <Link to="/Pricingcardinter">
                        <button style={{ backgroundColor: "#1C6BFE", color: "#FFFFFF" }} className='calculate-International'>International</button>

                    </Link>
                </div>

                <Container>
                    <Card style={{ padding: "10px" }}>
                        <Box sx={{ flexGrow: 1 }}>

                            {/* <div className='calculate-button'>
                            <Link to="/Pricing">
                                <button style={{ backgroundColor: "#E7F0FF", color: "#1C6BFE" }} className='calculate-India'>India</button></Link>


                            <Link to="/Pricingcardinter">
                                <button style={{ backgroundColor: "#1C6BFE", color: "#FFFFFF" }} className='calculate-International'>International</button>

                            </Link>
                        </div> */}
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={8} >
                                    {/* <Card> */}

                                    <div className='calculate-smallbutton'>

                                        <Link to="/Pricingcardinter">
                                            <button style={{ backgroundColor: "#E7F0FF", color: "#1C6BFE" }} className='calculate-Monthly'>Monthly</button>

                                        </Link>

                                        <Link to='/Internyearpricing'>
                                            <button style={{ backgroundColor: "#1C6BFE", color: "#FFFFFF" }} className='calculate-Yearly'>Yearly
                                                <button className='discountbutton-opt'>10% OFF*</button>

                                            </button>
                                        </Link>



                                    </div>


                                    {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ mobile responsive card */}
                                    <div style={{ padding: "10px", color: "#606062", fontFamily: "system-ui" }} className="display-show">
                                        <h1 className='priceestimate' style={{ color: "#606062", fontSize: "20px", fontFamily: "Rubik", fontWeight: "bold" }}>Price Estimate</h1>
                                        <br />
                                        <hr className='calculate-hr-top' />
                                        <div style={{ display: "flex", fontWeight: "600" }}>
                                            <h1 style={{}}>Base price</h1>
                                            <h1 style={{ margin: "auto", textAlign: "right", paddingLeft: "60%", position: "absolute" }}> =  $  {this.state.baseprice}</h1>
                                        </div>
                                        <div style={{ display: "flex", fontSize: "12px" }}>
                                            <h1>$  0.024 / user</h1>
                                            <h1 style={{ paddingLeft: "20px" }}>x{this.state.price} users x 12 months</h1>
                                        </div>

                                        <div style={{ display: "flex", fontWeight: "600" }}>
                                            <h1 style={{}}>Onboarding fee</h1>
                                            <h1 style={{ margin: "auto", textAlign: "right", paddingLeft: "60%", position: "absolute" }}> = $  {this.state.onboarding}</h1>
                                        </div>
                                        <div style={{ display: "flex", fontSize: "12px" }}>
                                            <h1>$  1.22 / user </h1>
                                            <h1 style={{ paddingLeft: "20px" }}>x {this.state.price} users</h1>
                                        </div>



                                        <div style={{ display: "flex", fontWeight: "600" }}>
                                            <h1 style={{}}>GST</h1>
                                            <h1 style={{ margin: "auto", textAlign: "right", paddingLeft: "60%", position: "absolute" }}> = $  {this.state.gst}</h1>
                                        </div>
                                        <div style={{ display: "flex", fontSize: "12px" }}>
                                            <h1> 18%</h1>
                                        </div>

                                        <hr className='calculate-hr' />

                                        <div style={{ display: "flex", fontWeight: "600" }}>
                                            <h1 style={{}}>Total paid in the first year</h1>
                                            <h1 style={{ margin: "auto", textAlign: "right", paddingLeft: "60%", position: "absolute" }}>= $  {this.state.totalpaid}</h1>
                                        </div>
                                    </div>
                                    {/* @@@@@@@@@@@@@@################################ mobile responsive end */}



                                    {/* <div style={{ overflow: "none" }}> */}
                                    <div style={{ overflow: "none", }} className="display-none-form">
                                        <h1 className='priceestimate'>Price Estimate</h1>
                                        <br />
                                        <hr className='calculate-hr-top' />

                                        <table className=''>
                                            <tr>
                                                <td>Base price</td>
                                                <td>$  0.024 / user</td>
                                                <td className='usermonth'>x{this.state.price} users x 12 months </td>
                                                <td>=</td>
                                                <td>$  {this.state.baseprice}</td>


                                            </tr>
                                            <tr>
                                                <td>Onboarding fee </td>
                                                <td>$  1.22 / user</td>
                                                <td className='usermonth'>x {this.state.price} users    </td>
                                                <td>=</td>
                                                <td>$  {this.state.onboarding}</td>


                                            </tr>
                                            <tr>
                                                <td>GST  </td>
                                                <td>18%</td>
                                                <td></td>
                                                <td>=</td>
                                                <td>$  {this.state.gst}</td>

                                            </tr>
                                        </table>

                                        <hr className='calculate-hr' />

                                        <table className=''>


                                            <tr>
                                                <td>Total paid in the first year   </td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>=</td>
                                                <td>$  {this.state.totalpaid}</td>

                                            </tr>
                                        </table>
                                    </div>

                                    {/* </Card> */}
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <div className='calculate-buynow' style={{ paddingTop: "20%" }}>
                                        <h1 className='calculate-rs'> $  {this.state.baseprice} / Year</h1>
                                        <p className='calculate-fee'>+  {this.state.onetime} of one time fee</p>
                                        <Link to="/Login">
                                            <button className='calculate-buttons'>Buy Now</button>
                                        </Link>
                                    </div>
                                </Grid>
                            </Grid>
                        </Box></Card>
                </Container>

            </>



        );
    }
}

export default App;