import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
// import attendancelsymboll from '../../../Components/Image/Group 6330@2x.png';
import pricingcardsymboll from '../../../Components/Image/Group 6330@2x.png';
import { padding } from '@mui/system';
import './Attendancemana.css';
import ReadmeMore from "./FAQ/ReadmeMore";
import ReadmeSecond from "./FAQ/ReadmeSecond";
import Readmethird from "./FAQ/Readmethird";




export default function FullWidthGrid() {
    return (
        <>


            <Box sx={{ flexGrow: 1 }} style={{ paddingTop: "50px" }}>

                
            </Box>

        </>
    );
}
