import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import logo from '../../../Components/Image/HRVerse@2x.png'
import './Signupform.css';
import Signup from '../Signupform/Signup';
import { Card } from '@mui/material';
import loginsymbol from '../../../Components/Image/Group 6110@2x.png'





export default function FullWidthGrid() {
    return (<>


        <div className='login-bg' style={{ padding: "40px" }}>

            <Container>

                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} sm={6}>



                            <img className='formposition-signup' src={logo} />


                            <div className='contactdetail'>
                                <h1 className='callus'> Call Us</h1>
                                <h6 className='callus-paragraph'>

                                    Feel Free To Connect With Us For Any Additional Information/Query.
 </h6>
                                <a href="tel:+4733378901">
                                    <p className='contactnumber'>   +12345127890 </p>
                                </a>
                            </div>

                            <img src={loginsymbol} className="signupsymboll" />
                        </Grid>
                        <Grid item xs={12} md={6} sm={6}>
                            <br />
                            <br />

                            <Card>
                                <Signup />

                            </Card>


                        </Grid>
                        <Grid item xs={12} md={6} >

                            {/* <div className='contactdetail'>
                                <h1> Call us</h1>
                                <h12>You can also call us first! Scale up your business with us starting today. </h12>
                                <p> +12345127890 </p>

                            </div> */}
                        </Grid>

                    </Grid>
                </Box>
            </Container>





        </div>


    </>


    );
}





