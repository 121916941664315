import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import cardlogo from '../../../Components/Image/payroll-services-feature-icon-1@2x.png';
import "./Servicescard.css";
import festuresymboll from '../../../Components/Image/Group 6330@2x.png';

import FeaturesCardScroll from '../Servicescard/FeaturesCardScroll/FeaturesCardScroll';



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function FullWidthGrid() {
    return (
        <>
            <br />


            <Container>


                <h1 className='featuresfont' style={{ textAlign: "center" }} >Features</h1>
                {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ mobile scrolling services card import */}

                <FeaturesCardScroll />



                <br />

                <Box sx={{ flexGrow: 1 }} >
                    <Grid container spacing={1} >
                        <Grid xs={12} md={4} container
                            direction="column-reverse"
                            justifyContent="center"
                            alignItems="center">
                            <Card className='cardzoom' sx={{ maxWidth: 360 }} style={{ textAlign: "center", marginBottom: "2%" }}>
                                <img className='cardlogo' src={cardlogo} justifyContent="center" style={{ width: "30%", height: "30%", textAlign: "center" }} />
                                <h1 className='transactional'>Employee Onboarding</h1>
                                {/* <br /> */}
                                <p className='cardparagraph' style={{ textAlign: "justify", padding: "20px" }}>
                                    Employee onboarding is crucial part for any organization, Seamless onboarding process provides positive experience to employees and can be quickly deployed to become productive and effective contributors to your organizations.  Trouble free onboarding process with HRVERSE ensures positive employee experience and optimum employee engagement during various stages of employee onboarding.
                                    <br />
                                    <br />
                                    <br />

                                </p>



                                {/* <img width={30} className='festuresymboll' src={festuresymboll} />

                                <p className='cardparagraph'>Employee onboarding is crucial part for any organization, Seamless onboarding process provides positive experience to employees and can be quickly deployed to become productive and effective contributors to your organizations.</p>

                                <br />
                                <img width={30} className='festuresymboll' src={festuresymboll} />

                                <p className='cardparagraph'> Trouble free onboarding process with HRVERSE ensures positive employee experience and optimum employee engagement during various stages of employee onboarding.</p> */}

                            </Card>
                        </Grid>
                        <Grid xs={12} md={4} container
                            direction="column-reverse"
                            justifyContent="center"
                            alignItems="center">
                            <Card className='cardzoom' sx={{ maxWidth: 360 }} style={{ textAlign: "center", marginBottom: "2%" }}>
                                <img className='cardlogo' src={cardlogo} justifyContent="center" style={{ width: "30%", height: "30%", textAlign: "center" }} />
                                <h1 className='transactional'>Asset Management</h1>
                                {/* <br /> */}

                                <p className='cardparagraph' style={{ textAlign: "justify", padding: "20px" }}>
                                    With multiple departments and number of employees in each department, it is imperative to keep a track of assets allocated to each employee. HRVERSE will keep complete records of the assets assigned to respective employee along with maintenance and service required for particular asset. HRVERSE enables organizations to define type of asset allocated to employee so that these assets can be returned during the exit formalities with clear records of assets allocation.

                                </p>



                                {/* ########### */}


                                {/* <img width={30} className='festuresymboll' src={festuresymboll} />
                                <p className='cardparagraph'>With multiple departments and number of employees in each department, it is imperative to keep a track of assets allocated to each employee. </p>

                                <br />

                                <img width={30} className='festuresymboll' src={festuresymboll} />

                                <p className='cardparagraph'>
                                    HRVERSE will keep complete records of the assets assigned to respective employee along with maintenance and service required for particular asset. HRVERSE enables organizations to define type of asset allocated to employee so that these assets can be returned during the exit formalities with clear records of assets allocation.

                                </p> */}

                            </Card>
                        </Grid>
                        <Grid xs={12} md={4} container
                            direction="column-reverse"
                            justifyContent="center"
                            alignItems="center">
                            <Card className='cardzoom' sx={{ maxWidth: 360 }} style={{ textAlign: "center", marginBottom: "2%" }}>
                                <img className='cardlogo' src={cardlogo} justifyContent="center" style={{ width: "30%", height: "30%", textAlign: "center" }} />
                                <h1 className='transactional'>Expenses Management</h1>
                                {/* <br /> */}

                                <p className='cardparagraph' style={{ textAlign: "justify", padding: "20px" }}>



                                    HRVERSE simplifies tracking of various expenses incurred by employees at various levels. Employees incurring expenses and submit claims for reimbursements can be settled without any hassle. Track record of the expenses incurred by each employee can be maintained and utilized for future reference. HRVERSE also provides dedicated dashboards for employee to submit their expenses, get approvals and understand the status on reimbursements.



                                </p>

                                {/* @@@@@@@@@@@@@@@@@@@@ */}
                                {/* <img width={30} className='festuresymboll' src={festuresymboll} />

                                <p className='cardparagraph'>HRVERSE simplifies tracking of various expenses incurred by employees at various levels. Employees incurring expenses and submit claims for reimbursements can be settled without any hassle.</p>

                                <br />
                                <img width={30} className='festuresymboll' src={festuresymboll} />

                                <p className='cardparagraph'>
                                    Track record of the expenses incurred by each employee can be maintained and utilized for future reference. HRVERSE also provides dedicated dashboards for employee to submit their expenses, get approvals and understand the status on reimbursements.
                                </p> */}

                            </Card>
                        </Grid>



                    </Grid>
                </Box>
            </Container>
            <br />
            <br />


        </>

    );
}
