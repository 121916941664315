import React from "react";
import Counter from "./count";
import "./Counter.css";

export default function App() {
    return (
        <div className="App">
            <div className="photo-profile">{/* <img  /> */}</div>
            <div className="numbers">
                {/* <Counter number={105} title="Posts" /> */}

                <Counter number={60000} title="" />
                <div style={{ marginTop: "70px", marginRight: "80px" }} className="customer-3 display-none-form">
                    <h1 style={{ marginLeft: "-150px" }}> Daily Logins</h1>
                </div>

                {/* <Counter number={5175} title="Followers" /> */}


                {/* <Counter number={468} title="Following" /> */}
            </div>
        </div>
    );
}
