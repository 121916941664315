import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import customerimage from '../../Components/Image/customer@2x.png'
import Counter from '../../Pages/Home/Counter/Counter';
import Counter2 from '../../Pages/Home/Counter/Counter';



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function FullWidthGrid() {
    return (
        <>
            <Container>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container style={{ textAlign: "center" }} spacing={0.5}>
                        <Grid item xs={12} md={5}>
                            <div>
                                <img src={customerimage} className="customericon" style={{ marginLeft: "100px" }} />
                                <div style={{}}>
                                    <Counter />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <div className='vl'></div>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <div>
                                <img src={customerimage} className="customericon" />

                                <Counter2 />  </div>
                        </Grid>

                    </Grid>
                </Box>
            </Container>
        </>
    );
}
