import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
// import './Pricing.css';
import Pricingcard from '../Pricingcard/Pricingcard';
import Container from '@mui/material/Container';


import Customer from '../../Home/Customer/Customer';
import CustomerResponsive from '../../Home/Customer/CustomerResponsive';


import Footer from '../../Footer/Footer';
import Copyright from '../../Copyright/Copyright/Copyright';
import Sidebar from '../../../Components/Sidebar';
import CalculateYourPlan from '../CalculateYourPlan/CalculateYourPlan';
import Internationprice from '../CalculateYourPlan/Internationprice';
import FooterMobile from '../../FooterMobile/FooterMobile';
import ScrollingPriceCard from "../../ScrollingPriceCard/ScrollingPriceCard";





const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function FullWidthGrid() {
    return (<>

        <Sidebar />
        <Box sx={{ flexGrow: 1 }} style={{ paddingTop: "150px" }}>
            <Grid container spacing={2}
                // direction="column-reverse"
                justifyContent="center"
                alignItems="center">
                <h1><span className='pricingheading'>LubriChem </span> <span className='pricingheadingsecond'>Subscription Plans</span></h1>


                {/* <br /> */}
                {/* <p className='pricingparagraph'> Powerful, comprehensive & yet the most affordable HR software you can buy without <br />breaking your bank.</p> */}

            </Grid>
            <p className='pricingparagraph'> Powerful, comprehensive & yet the most affordable HR software you can buy without <br />breaking your bank.</p>
        </Box>




        {/* <Pricingcard /> */}
        <Container>
            <ScrollingPriceCard />
        </Container>

        <br />
        <br />

        <div style={{ overflow: "hidden" }}>
            <Internationprice />
        </div>
        <br />
        <br />
        <br />

        {/*         
        <CalculateYourPlan />
        <br />
        <br />
        <br /> */}




        <Container className=''>
            {/* <hr className='horizontalline' /> */}
        </Container>

        <div className='display-none-form'>
            <Customer />
        </div>
        <div className='display-show'>
            <CustomerResponsive />
        </div>


        {/* <Container className=''> */}
        {/* <hr className='horizontalline2' /> */}
        <hr className='horizontallinebottom' />

        {/* </Container> */}

        {/* <Footer />
        <hr className='horizontallinebottom' />
        <Copyright /> */}

        <div className='display-none-form'>
            <Footer />
            {/* <Container className=''> */}
            <hr className='horizontallinebottom' />
            {/* </Container> */}
            <Copyright />
        </div>
        <div className='display-show'>
            <FooterMobile />
        </div>


    </>


    );
}
