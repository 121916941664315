import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
// import './CompanyDetailsForm.css';
import Button from '@mui/material/Button';
import { useForm } from "react-hook-form";
import DeleteIcon from '@mui/icons-material/Delete';


// http://127.0.0.1:8000/webApi/apply-credit 
// Api 
const client = axios.create({
    method: 'post', //you can set what request you want to be


    // baseURL: 'http://127.0.0.1:8000/webApi/apply-credit/',

    baseURL: 'http://app.hrvers.com/webApi/apply-credit/',

});

export default function FullWidthGrid() {




    // @@@@@@@@@@@@@@@@@@@@@@@@
    const [company_name, setCompanyName] = useState('');
    const [type_of_company, setCompanyType] = useState('');
    const [industry, setIndustry] = useState('');
    const [register_address, setRegisterAdd] = useState('');
    const [startup_register, setStartp] = useState('');
    const [state, setStates] = useState('');
    const [city, setCity] = useState('');

    const [name, setName] = useState('');
    const [contact_no, setContact] = useState('');
    const [email, setEmail] = useState('');

    const [smail, set_sEmail] = useState('');
    const [scontact_no, set_sContact] = useState('');
    const [sname, set_sName] = useState('');






    const [posts, setPosts] = useState([]);
    // handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        addPosts(company_name, type_of_company, industry, register_address,
            startup_register, state, city, name, contact_no, email, smail, scontact_no, sname);
        console.log({ company_name });
        console.log({ type_of_company });
        console.log({ industry });
        console.log({ register_address });
        console.log({ startup_register });
        console.log({ state });
        console.log({ city });

        console.log({ name });
        console.log({ contact_no });
        console.log({ email });

        console.log({ smail });
        console.log({ scontact_no });
        console.log({ sname });



    };

    // POST with Axios
    const addPosts = async (company_name, type_of_company, industry, register_address, startup_register, state, city, name, contact_no, email, smail, scontact_no, sname) => {
        try {
            let response = await client.post('', {
                company_name: company_name,
                type_of_company: type_of_company,
                industry: industry,
                register_address: register_address,
                startup_register: startup_register,
                state: state,
                city: city,

                fName: name,
                fContact: contact_no,
                fEmail: email,

                s_Name: sname,
                s_Email: smail,
                s_Contact: scontact_no,








            });


            setPosts([response.data, ...posts]);
            setCompanyName('');
            setCompanyType('');
            setIndustry('');
            setRegisterAdd('');
            setStartp('');
            setStates('');
            setCity('');

            setName('');
            setContact('');
            setEmail('');

            set_sEmail('');
            set_sContact('');
            set_sName('');


        } catch (error) {
            console.log(error);
        }
    };
    // ##########################



    const [formFields, setFormFields] = useState([
        { name: '', contact_no: '', email },
    ])

    const handleFormChange = (event, index) => {
        let data = [...formFields];
        data[index][event.target.name] = event.target.value;
        setFormFields(data);
    }

    const submit = (e) => {
        e.preventDefault();
        console.log(formFields)
    }

    const addFields = () => {
        let object = {
            name: '',
            age: ''
        }

        setFormFields([...formFields, object])
    }

    const removeFields = (index) => {
        let data = [...formFields];
        data.splice(index, 1)
        setFormFields(data)
    }





    // ##############################

    // const { register, handleSubmit, watch, formState: { errors } } = useForm();
    // const onSubmit = data => console.log(data);

    // console.log(watch("example")); 

    // watch input value by passing the name of it


    return (

        <>
            {/* <form onSubmit={handleSubmit(onSubmit)}> */}
            {/* {message} */}
            <form method="post" onSubmit={handleSubmit}>
                <Container>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>

                            <Grid item xs={12} md={12} sm={12} lg={12}>
                                <h1 className='formheading' >Company Details</h1>
                            </Grid>
                            <Grid item xs={12} md={12} sm={12} lg={12}>
                                <fieldset>
                                    <legend>Company Name </legend>
                                    {/* <label for="fname">First name:</label> */}
                                    <input className='inputfeild' type="text" id="fname" name="fname" placeholder='Enter Company Name'
                                        required
                                        value={company_name}
                                        onChange={(e) => setCompanyName(e.target.value)}
                                        onFocus
                                    />
                                </fieldset>
                            </Grid>
                            <Grid item xs={12} md={6} sm={6} lg={6}>
                                <fieldset>
                                    <legend>Type of Company </legend>
                                    {/* <label for="fname">First name:</label> */}
                                    {/* <input className='inputfeild' type="text" id="fname" name="fname" placeholder='Enter number of employee' /> */}
                                    <select name="cars" id="cars" className='inputfeild' style={{ width: "100%    " }}
                                        required
                                        value={type_of_company}
                                        onChange={(e) => setCompanyType(e.target.value)}>
                                        <option value="1">Demo</option>
                                        <option value="2">Demo1</option>
                                        <option value="3">Demo2</option>
                                        <option value="4">1Demo3</option>
                                    </select>
                                </fieldset>
                            </Grid>
                            <Grid item xs={12} md={6} sm={6} lg={6}>
                                <fieldset>
                                    <legend>Industry</legend>
                                    <input className='inputfeild' type="text" id="fname" name="fname" placeholder=' Enter Industry Type'
                                        required
                                        value={industry}
                                        onChange={(e) => setIndustry(e.target.value)}
                                    />
                                </fieldset>
                            </Grid>
                            <Grid item xs={12} md={12} sm={12} lg={12}>
                                <fieldset>
                                    <legend>Registered Address </legend>
                                    <input className='inputfeild' type="text" id="fname" name="fname" placeholder='Enter  Company Registered Address'
                                        required
                                        value={register_address}
                                        onChange={(e) => setRegisterAdd(e.target.value)} />
                                </fieldset>
                            </Grid>
                            <Grid item xs={12} md={12} sm={12} lg={12}>
                                <fieldset>
                                    <legend>Upload Startup Registered </legend>
                                    <input className='inputfeild' type="file" id="fname" name="fname" placeholder='Enter Upload Startup Registered '
                                        required
                                        value={startup_register}
                                        onChange={(e) => setStartp(e.target.value)} />
                                </fieldset>
                            </Grid>

                            <Grid item xs={12} md={6} sm={6} lg={6}>
                                <fieldset>
                                    <legend>State  </legend>
                                    <input className='inputfeild' type="text" id="fname" name="fname" placeholder='Enter State '
                                        required
                                        value={state}
                                        onChange={(e) => setStates(e.target.value)} />
                                </fieldset>
                            </Grid>  <Grid item xs={12} md={6} sm={6} lg={6}>
                                <fieldset>
                                    <legend>City  </legend>
                                    <input className='inputfeild' type="text" id="fname" name="fname" placeholder='Enter City'
                                        required
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)} />
                                </fieldset>
                            </Grid>

                            <Grid item xs={12} md={12} sm={12} lg={12}>
                                <h1 className='formheading' >Founder/s Details  </h1>
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={4}>
                                <fieldset>
                                    <legend>Name </legend>
                                    <input className='inputfeild' type="text" placeholder='Enter Your Name  '
                                        value={name}
                                        onChange={(e) => setName(e.target.value)} />
                                </fieldset>
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={4}>
                                <fieldset>
                                    <legend>Contact No.</legend>
                                    <input className='inputfeild' type="number" placeholder='Enter Your Contact Number'
                                        min={12}
                                        required
                                        value={contact_no}
                                        onChange={(e) => setContact(e.target.value)} />
                                </fieldset>
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={4}>
                                <fieldset>
                                    <legend>Email ID</legend>
                                    <input className='inputfeild' type="email" placeholder='Enter Your Email'
                                        required
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)} />
                                </fieldset>
                            </Grid>

                            <Grid item xs={12} md={12} sm={12} lg={12}>
                                <h1 className='formheading' >Founder/s Details  </h1>
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={4}>
                                <fieldset>
                                    <legend>Name </legend>
                                    <input className='inputfeild' type="text" placeholder='Enter Your Name  '
                                        value={sname}
                                        onChange={(e) => set_sName(e.target.value)} />
                                </fieldset>
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={4}>
                                <fieldset>
                                    <legend>Contact No.</legend>
                                    <input className='inputfeild' type="number" placeholder='Enter Your Contact Number'
                                        min={10}
                                        value={scontact_no}
                                        onChange={(e) => set_sContact(e.target.value)} />
                                </fieldset>
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={4}>
                                <fieldset>
                                    <legend>Email ID</legend>
                                    <input className='inputfeild' type="email" placeholder='Enter Your Email'
                                        value={smail}
                                        onChange={(e) => set_sEmail(e.target.value)} />
                                </fieldset>
                            </Grid>

                            <br />


                        </Grid>
                    </Box>
                </Container>




                {/* {formFields.map((form, index) => {
                    return (
                        <Container>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={3} sm={12} lg={3}>
                                        <fieldset>
                                            <legend>Name </legend>
                                            <input className='inputfeild' type="text" placeholder='Enter Your Name  '
                                                value={name}
                                                onChange={(e) => setName(e.target.value)} />
                                        </fieldset>
                                    </Grid>
                                    <Grid item xs={12} md={3} sm={12} lg={3}>
                                        <fieldset>
                                            <legend>Contact No.</legend>
                                            <input className='inputfeild' type="text" placeholder='Enter Your Contact Number'
                                                value={contact_no}
                                                onChange={(e) => setContact(e.target.value)} />
                                        </fieldset>
                                    </Grid>
                                    <Grid item xs={12} md={3} sm={12} lg={3}>
                                        <fieldset>
                                            <legend>Email ID</legend>
                                            <input className='inputfeild' type="text" placeholder='Enter Your Email'
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)} />
                                        </fieldset>
                                    </Grid>
                                    <Grid item xs={12} md={3} sm={12} lg={3}>
                                        <button onClick={() => removeFields(index)}><DeleteIcon style={{ width: "100%", height: "20px", marginTop: "20px", color: "#FF0000" }} /></button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Container>
                    )
                })} */}



                {/* <Container>
                    <Grid item xs={12} md={12} sm={12} lg={12}>
                        <br />
                        <Button onClick={addFields} variant="contained" style={{ backgroundColor: "#707070", color: "#FFFFFF", fontFamily: "lato" }}>Add Founder</Button>
                    </Grid>
                </Container> */}
                <Container>
                    <br />
                    <Grid item xs={12} md={12} sm={12} lg={12} style={{ margin: "auto", alignItems: "center", textAlign: "center" }}>
                        <button type='submit' className='formbutton' style={{ width: "50%" }} fullWidth variant="contained"> Submit  </button>
                    </Grid>
                </Container>




            </form>

            {/* // ########################################## */}


        </>

    );
}








