import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// import './Blog.css'
// import Startforfree from '../Home/Startforfree/Startforfree';
// import Customer from '../Home/Customer/Customer';
// import blogimage from "../../Components/Image/Group 122@2x.png"
import blogimage from '../../../Components/Image/Group 122@2x.png'
import ReadmoreBlog from './ReadmoreBlog.css';

import Grid from '@mui/material/Grid';
// import Footer from '../Footer/Footer';
// import Copyright from '../Copyright/Copyright/Copyright'
import { Link } from 'react-router-dom';
import zIndex from '@mui/material/styles/zIndex';
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function FullWidthGrid() {
    return (

        <>


            <section className='sectionblog' style={{ color: "#707070", backgroundColor: "", width: "100%", }}>

                <h1 className='blogcardheading'>Recommended for you</h1>

                <Container style={{ color: "#707070", }}>



                    <Box className='' sx={{ flexGrow: 1 }} style={{ paddingTop: "10px" }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4} sm={4}>

                                {/* <Item> */}
                                {/* ##################### */}
                                <Card sx={{ maxWidth: 345 }} className="zoom">
                                    <CardMedia
                                        // style={{width:"100%",height:"100%" , objectFit:"cover"}}
                                        sx={{ height: 200, objectFit: "contain" }}
                                        image={blogimage}
                                        title="green iguana"
                                    />
                                    <CardContent>


                                        <div className='titelfont ' gutterBottom variant="h5" component="div" style={{ color: "#707070" }}>

                                            {/* <div className='paddingbox'> <div className="parallelogram"> </div>  </div> */}
                                            <h1 className='cardcontentfirst'>Lizards are a widespread group of squamate reptiles, with over 6,000
                                                species, ranging across all continents except Antarctica</h1>
                                            <p className='cardcontentsecond'>177 views • 8 mins read • 04 Jan 2023</p>





                                            {/* 
                    <div class="tooltip">Home
                      <span class="tooltiptext"> </span>
                    </div> */}



                                        </div>

                                    </CardContent>
                                    <CardActions>

                                        <Link to="/ReadmoreBlog">  <Button size="small">Read More</Button>    </Link>




                                    </CardActions>
                                </Card>


                                {/* @@@@@@@@ */}

                                {/* </Item> */}



                            </Grid>
                            <Grid item xs={12} md={4} sm={4}>
                                <Card sx={{ maxWidth: 345 }} className="zoom">
                                    <CardMedia
                                        // style={{width:"100%",height:"100%" , objectFit:"cover"}}
                                        sx={{ height: 200, objectFit: "contain" }}
                                        image={blogimage}
                                        title="green iguana"
                                    />
                                    <CardContent>


                                        <div className='titelfont' gutterBottom variant="h5" component="div" style={{ color: "#707070" }}>

                                            {/* <div className='paddingbox'> <div className="parallelogram"> </div>  </div> */}
                                            <h1 className='cardcontentfirst'>Lizards are a widespread group of squamate reptiles, with over 6,000
                                                species, ranging across all continents except Antarctica</h1>
                                            <p className='cardcontentsecond'>177 views • 8 mins read • 04 Jan 2023</p>





                                            {/* 
  <div class="tooltip">Home
    <span class="tooltiptext"> </span>
  </div> */}



                                        </div>

                                    </CardContent>
                                    <CardActions>

                                        <Button size="small">Read More</Button>
                                    </CardActions>
                                </Card>
                            </Grid>

                            <Grid item xs={12} md={4} sm={4}>


                                <Card sx={{ maxWidth: 345 }} className="zoom">
                                    <CardMedia
                                        // style={{width:"100%",height:"100%" , objectFit:"cover"}}
                                        sx={{ height: 200, objectFit: "contain" }}
                                        image={blogimage}
                                        title="green iguana"
                                    />
                                    <CardContent>


                                        <div className='titelfont' gutterBottom variant="h5" component="div" style={{ color: "#707070" }}>

                                            {/* <div className='paddingbox'> <div className="parallelogram"> </div>  </div> */}
                                            <h1 className='cardcontentfirst'>Lizards are a widespread group of squamate reptiles, with over 6,000
                                                species, ranging across all continents except Antarctica</h1>
                                            <p className='cardcontentsecond'>177 views • 8 mins read • 04 Jan 2023</p>









                                        </div>

                                    </CardContent>
                                    <CardActions>

                                        <Button size="small">Read More</Button>
                                    </CardActions>
                                </Card>
                            </Grid>







                        </Grid>
                    </Box></Container>


            </section>



        </>
    );
}
