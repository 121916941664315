// import logo from './logo.svg';
import { useEffect } from 'react';
import './App.css';
// import ReactDOM from "react-dom/client";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate, useLocation, HashRouter } from "react-router-dom";
import "@fontsource/rubik"; // Defaults to weight 400.
// import React, { useEffect } from "react";
import Sidebar from './Components/Sidebar';
// import Home from "./Components/Home/Home";
// import NoPage from './Components/Layout/Layout';
// import Blogs from "./Components/Blogs/Blogs";
import Blogs from './Pages/Blogs/Blogs';
import Typewriter from "typewriter-effect";


// ####################
import Layout from "./Demopages/Layout/Layout";
import Home from "./Pages/Home/Home";
// import Blogs from "./Demopages/Blogs/Blogs";
import Contact from "./Demopages/Contact/Contact";
import NoPage from "./Demopages/NoPage/NoPage";
import Services from './Pages/Services/Services';
import Pricing from './Pages/Pricing/Pricing';
import Recruitment from './Pages/Features/Recruitment/Recruitment';
import RustPreventiveOil from './Pages/Features/Recruitment/RustPreventiveOil';
import Straightcuttingoil from './Pages/Features/Recruitment/Straightcuttingoil';
import Cleaning from './Pages/Features/Recruitment/Cleaning';
import Generalpurpose from './Pages/Features/Recruitment/Generalpurpose';
import Hydraulicoil from './Pages/Features/Recruitment/Hydraulicoil';
import TurbineOil from './Pages/Features/Recruitment/TurbineOil';
import CompressorOil from './Pages/Features/Recruitment/CompressorOil';
import SpindleOil from './Pages/Features/Recruitment/SpindleOil';
import QuenchingOil from './Pages/Features/Recruitment/QuenchingOil';
import HeatTransfer from './Pages/Features/Recruitment/HeatTransfer';
import TappingOil from './Pages/Features/Recruitment/TappingOil';
import EdmOil from './Pages/Features/Recruitment/EdmOil';
import Drawing from './Pages/Features/Recruitment/Drawing';
import AutomotiveOil from './Pages/Features/Recruitment/AutomotiveOil';
import EngineOil from './Pages/Features/Recruitment/EngineOil';
import GearOil from './Pages/Features/Recruitment/GearOil';
import Subscription from './Pages/Subscription/Subscription';
import ReadmoreBlog from './Pages/Blogs/ReadmoreBlog/ReadmoreBlog';
import Login from './Pages/Login/Login';
import ReseatPassword from './Pages/Login/Loginpage/ReseatPassword';
import Signupform from './Pages/Login/Signupform/Signupform';
import Pricingcardyear from './Pages/Pricing/Pricingcard/Pricingcardyear';
// import Internationprice from './Pages/Pricing/CalculateYourPlan/Internationprice';
import Pricingcardinter from './Pages/Pricing/Pricingcard/Pricingcardinter';
import Internyearpricing from './Pages/Pricing/Pricingcard/Internyearpricing';
// import CalculateYourPlan from './Pages/Pricing/CalculateYourPlan/CalculateYourPlan';
// import Sidescroll from "./Pages/Sidescroll/Sidescroll";
// import DemoPrice from './Demopages/Contact/DemoPrice';
import Formvalidatin from './Demopages/Formvalidatin';
import Positionfix from './Demopages/positionfix/Positionfix';
import Nutshellscroll from './Demopages/Nutshellscroll';
import Hoverjumpdemo from './Demopages/hoverjumpdemo/Hoverjumpdemo';
import CustomerLoginSection from './Demopages/CustomerLogin/CustomerLoginSection';
import Startup from './Pages/Startup/Startup';
import BackgroundSection from './Demopages/BackgroundSection/BackgroundSection';
import ScrollingPriceCard from './Pages/ScrollingPriceCard/ScrollingPriceCard';
import FeaturesCardScroll from './Pages/Services/Servicescard/FeaturesCardScroll/FeaturesCardScroll';

import StartupBanner from './Pages/Startup/Startupfirst/StartupBanner';
import Faq from './Pages/Features/Recruitment/FAQ/Faq';
import Scrolltotop from './Components/Scrolltotop/Scrolltotop';
import ScrollIntoView from './Components/Scrolltotop/ScrollIntoView';
import Form_demo from "./Demopages/Form_demo/Form_demo";



// @@@@@@@@@@@@@@@@@@@



function App() {




  return (
    <div style={{ margin: "0px", padding: "0px" }} className="App">
      <header className="App-header">
        {/* <BrowserRouter> */}
        <BrowserRouter>
          <Scrolltotop />
          <h1>hiiiii</h1>
          {/* <Routes>
            <Route path="/Login" element={<Login />} />
            <Route path="/Sidescroll" element={<Sidescroll />} />
          </Routes> */}
          {/* <Sidebar /> */}
          <Routes>
            {/* <Sidebar /> */}
            <Route path="/" element={<Home />} />
            {/* <Route path="/Home" element={<Home />} /> */}
            <Route path="/Blogs" element={<Blogs />} />
            <Route path="/Recruitment" element={<Recruitment />} />
            <Route path="/RustPreventiveOil" element={<RustPreventiveOil />} />
            <Route path="/Straightcuttingoil" element={<Straightcuttingoil />} />
            <Route path="/Cleaning" element={<Cleaning />} />
            <Route path="/Generalpurpose" element={<Generalpurpose />} />
            <Route path="/Hydraulicoil" element={<Hydraulicoil />} />  
            <Route path="/TurbineOil" element={<TurbineOil />} />  
            <Route path="/CompressorOil" element={<CompressorOil />} /> 
            <Route path="/SpindleOil" element={<SpindleOil />} />   
            <Route path="/QuenchingOil" element={<QuenchingOil />} /> 
            <Route path="/HeatTransfer" element={<HeatTransfer />} />              
            <Route path="/TappingOil" element={<TappingOil />} /> 
            <Route path="/EdmOil" element={<EdmOil />} />  
            <Route path="/Drawing" element={<Drawing />} />                                                                            
            <Route path="/AutomotiveOil" element={<AutomotiveOil />} />  
            <Route path="/EngineOil" element={<EngineOil />} />
            <Route path="/GearOil" element={<GearOil />} />                                                                                                                                                              
            <Route path="/Services" element={<Services />} />
            <Route path="/Pricing" element={<Pricing />} />
            <Route path="/Subscription" element={<Subscription />} />
            <Route path="/ReadmoreBlog" element={<ReadmoreBlog />} />
            <Route path="/ReseatPassword" element={<ReseatPassword />} />
            <Route path="/Signupform" element={<Signupform />} />
            {/* <Route path="/CalculateYourPlan" element={<CalculateYourPlan />} /> */}
            <Route path="/Pricingcardinter" element={<Pricingcardinter />} />
            <Route path="/Pricingcardyear" element={<Pricingcardyear />} />
            <Route path="/internyearpricing" element={<Internyearpricing />} />
            {/* <Route path="*" element={<h1 className="error"></h1>} /> */}
            {/* demo##############  demo*/}
            <Route path="/Formvalidatin" element={<Formvalidatin />} />
            <Route path="/Hoverjumpdemo" element={<Hoverjumpdemo />} />
            <Route path="/Nutshellscroll" element={<Nutshellscroll />} />
            <Route path="/Nutshellscroll" element={<Nutshellscroll />} />
            <Route path="/Positionfix" element={<Positionfix />} />
            <Route path="/CustomerLoginSection" element={<CustomerLoginSection />} />
            <Route path="/Startup" element={<Startup />} />
            <Route path="/BackgroundSection" element={<BackgroundSection />} />
            <Route path="/ScrollingPriceCard" element={<ScrollingPriceCard />} />
            <Route path="/FeaturesCardScroll" element={<FeaturesCardScroll />} />
            <Route path="/StartupBanner" element={<StartupBanner />} />
            <Route path="/Faq" element={<Faq />} />
            {/* Redirect path *  */}
            {/* <Route path="*" element={<StartupBanner />} /> */}
            <Route path="*" element={<ScrollIntoView />} />
            {/* <Route path="*" element={<Form_demo />} /> */}

          </Routes>
          {/* <DemoPrice /> */}
        </BrowserRouter>
        {/* </BrowserRouter> */}
        {/* <Sidebar /> */}

      </header>

    </div >
  );
}

export default App;




