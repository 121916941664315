import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import { Link } from 'react-router-dom';
import "./Treedrop.css";

export default function NestedList() {
    // const [open, setOpen] = React.useState(true);
    const [open, setOpen] = React.useState();




    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                    {/* Nested List Items */}
                </ListSubheader>
            }
        >
            <ListItemButton>
                <ListItemIcon>
                    {/* <SendIcon /> */}
                    <Link to="/">  Home </Link>
                </ListItemIcon>
                {/* <ListItemText primary="Sent mail" /> */}
            </ListItemButton>
            <ListItemButton onClick={handleClick}>
                <ListItemIcon>
                    
                        <ListItemText primary="Products" /></ListItemIcon>
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                
                <List component="div" disablePadding>
                    {/* <ListItemButton sx={{ pl: 4 }}> */}
                    {/* <ListItemIcon>
                            <StarBorder />
                        </ListItemIcon> */}
                    {/* <Link>                    
                           <ListItemText primary="Recruitment" /></Link> */}

                    {/* </ListItemButton> */}

                    <ListItemButton sx={{ pl: 3 }}>
                        <div style={{ display: "block", fontSize: "10px", color: "#333333" }}>
                            <div className='featurelist' style={{ display: "block" }}><Link onClick={goToTop_OnBoarding} to="/Recruitment">Water soluble oil</Link></div>
                            <div className='featurelist' style={{ display: "block" }}><Link onClick={goToTop_Document} to="/Straightcuttingoil">Straight Cutting Oil</Link></div>
                            <div className='featurelist' style={{ display: "block" }}><Link onClick={goToTop_Attendance} to="/RustPreventiveOil">Rust Preventive Oil</Link></div>
                            <div className='featurelist' style={{ display: "block" }}><Link onClick={goToTop_Leave} to="/Cleaning">Cleaning Chemicals  & Rust Removers</Link></div>
                            <div className='featurelist' style={{ display: "block" }}><Link onClick={goToTop_Payroll} to="/Generalpurpose">General Purpose Lubrication Oil</Link></div>
                            <div className='featurelist' style={{ display: "block" }}><Link onClick={goToTop_Exit} to="/Hydraulicoil">Hydraulic Oil</Link></div>
                            <div className='featurelist' style={{ display: "block" }}><Link onClick={goToTop_Expenses} to="/TurbineOil">Turbine Oil</Link></div>
                            <div className='featurelist' style={{ display: "block" }}><Link onClick={goToTop_Assets} to="/CompressorOil">Compressor Oil</Link></div>
                            <div className='featurelist' style={{ display: "block" }}><Link onClick={goToTop_Self} to="/SpindleOil">Spindle Oil</Link></div>
                            <div className='featurelist' style={{ display: "block" }}><Link onClick={goToTop_Self} to="/QuenchingOil">Quenching Oil</Link></div>
                            <div className='featurelist' style={{ display: "block" }}><Link onClick={goToTop_Self} to="/HeatTransfer">HEAT TRANSFER FLUIDS(THERMIC OILS)</Link></div>
                            <div className='featurelist' style={{ display: "block" }}><Link onClick={goToTop_Self} to="/TappingOil">Tapping Oil</Link></div>
                            <div className='featurelist' style={{ display: "block" }}><Link onClick={goToTop_Self} to="/EdmOil">EDM Oil</Link></div>
                            <div className='featurelist' style={{ display: "block" }}><Link onClick={goToTop_Self} to="/Drawing">Drawing,Punching & Rolling Oils</Link></div>
                            <div className='featurelist' style={{ display: "block" }}><Link onClick={goToTop_Self} to="/AutomotiveOil">Automotive Oil</Link></div>
                            <div className='featurelist' style={{ display: "block" }}><Link onClick={goToTop_Self} to="/EngineOil">Engine Oil</Link></div>
                            <div className='featurelist' style={{ display: "block" }}><Link onClick={goToTop_Self} to="/GearOil">Gear Oil</Link></div>

                        </div>




                        {/* <ListItemText primary="Starred" /> */}
                    </ListItemButton>
                </List>
            </Collapse>


            {/* $$$$$$$$$$$$$$$$$ */}
            <ListItemButton>
                <ListItemIcon>
                    <Link to="/Pricing">Career </Link>
                </ListItemIcon>
            </ListItemButton>

            <ListItemButton>
                <ListItemIcon>
                    <Link to="/Startup">About Us </Link>
                </ListItemIcon>
            </ListItemButton>



            
            {/* <ListItemButton onClick={handleClick}>
                <ListItemIcon>
                    <Link to="/Recruitment">
                        <ListItemText primary="Features" /></Link></ListItemIcon>
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton> */}
            {/* <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 3 }}>
                        <div style={{ display: "block", fontSize: "10px", color: "#333333" }}>
                            <div style={{ display: "block" }}><Link to="/Recruitment">Recruitment</Link></div>
                            <div style={{ display: "block" }}><Link to="/Recruitment">Employee Management</Link></div>
                            <div style={{ display: "block" }}><Link to="/Recruitment">Policy Documents</Link></div>
                            <div style={{ display: "block" }}><Link to="/Recruitment">Leave & Attendance</Link></div>
                            <div style={{ display: "block" }}><Link to="/Recruitment">Admin Congiguration</Link></div>
                            <div style={{ display: "block" }}><Link to="/Recruitment">Payroll</Link></div>
                            <div style={{ display: "block" }}><Link to="/Recruitment">Reports & Analytics</Link></div>
                            <div style={{ display: "block" }}><Link to="/Recruitment">Assets Management</Link></div>
                            <div style={{ display: "block" }}><Link to="/Recruitment">Assets Assignment</Link></div>
                        </div>
                    </ListItemButton>
                </List>
            </Collapse> */}
        </List>
    );
}






// fucntion to help scroll to top smoothly
const goToTop_OnBoarding = () => {
    window.scrollTo({
        top: 2000,
        // behavior: 'smooth',
        behavior: "auto"

    });
};
const goToTop_Document = () => {
    window.scrollTo({
        top: 2700,
        // behavior: 'smooth',
        behavior: "auto"

    });
};

const goToTop_Attendance = () => {
    window.scrollTo({
        top: 10,
        // behavior: 'smooth',
        behavior: "auto"

    });
};
const goToTop_Leave = () => {
    window.scrollTo({
        top: 1400,
        // behavior: 'smooth',
        behavior: "auto"

    });
};
const goToTop_Payroll = () => {
    window.scrollTo({
        top: 600,
        // behavior: 'smooth',
        behavior: "auto"

    });
};
const goToTop_Exit = () => {
    window.scrollTo({
        top: 4000,
        // behavior: 'smooth',
        behavior: "auto"

    });
};
const goToTop_Expenses = () => {
    window.scrollTo({
        top: 2000,
        // behavior: 'smooth',
        behavior: "auto"

    });
};
const goToTop_Assets = () => {
    window.scrollTo({
        top: 2000,
        // behavior: 'smooth',
        behavior: "auto"

    });
};
const goToTop_Self = () => {
    window.scrollTo({
        top: 3400,
        // behavior: 'smooth',
        behavior: "auto"
    });
};

    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ function scrolling end