import React from "react";
import Cardsa from '@mui/material/Card';
import Box from '@mui/material/Box';
import { Grid } from "@mui/material";
import Container from '@mui/material/Container';
import cardlogo from '../../../../Components/Image/payroll-services-feature-icon-1@2x.png';
import festuresymboll from '../../../../Components/Image/Group 6330@2x.png';



import './FeaturesCardScroll.css';






// const Card = (props) => (
//     <div className="card">
//         <img src={props.imgUrl}
//             alt={props.alt || 'Image'} />
//         <div className="card-content">
//             <h2 style={{ color: "red" }}>{props.title}</h2>
//             <p>{props.content}</p>
//         </div>
//     </div>
// );






const Card = (props) => (

    <>

        <div className="main-card-feature">
            <Cardsa sx={{}} style={{ display: "inline-flex", borderRadius: "6px", height: "500px" }} className="zoom-card card-style">
                <div style={{ display: 'block', margin: "auto" }}>
                    {/* <img className='cardlogo' src={cardlogo} justifyContent="center" style={{ width: "20%", height: "auto", textAlign: "center" }} /> */}
                    <img className='cardlogo' src={props.imgUrl} justifyContent="center" style={{ width: "20%", height: "auto", textAlign: "center" }} />
                    <h1 className="transaction-feature">{props.title}</h1>
                    <br />
                    {/* <img width={30} className='festuresymboll' src={festuresymboll} /> */}
                    <p className="sub-feratures" > {props.feature1}</p>
                    <br />
                    <br />
                    {/* <p className="sub-feratures"> {props.feature2}</p>
                    <br />
                    <br />
                    <br />
                    <img width={30} className='festuresymboll' src={festuresymboll} />
                    <p className="sub-feratures"> {props.feature3}</p>
                    <p className="sub-feratures"> {props.feature4}</p>
                    <br />
                    <br />
                    <br /> */}

                </div>
            </Cardsa>
        </div>
    </>

);




// const CardContainer = (props) => (
//     <div className="cards-container">
//         {
//             props.cards.map((card) => (
//                 <Card title={card.title}
//                     content={card.content}
//                     imgUrl={card.imgUrl} />
//             ))
//         }
//     </div>
// );



const CardContainer = (props) => (
    <>
        <div style={{ textAlign: 'center', }}>
            <div className="cards-container" >
                {
                    props.cards.map((card) => (
                        <Card
                            link={card.link}

                            money={card.money}
                            imgUrl={card.imgUrl}

                            title={card.title}
                            feature1={card.feature1}
                            feature2={card.feature2}
                            feature3={card.feature3}
                            feature4={card.feature4}
                            feature5={card.feature5}
                            feature6={card.feature6}
                            feature7={card.feature7}
                            feature8={card.feature8}
                            feature9={card.feature9}
                            content={card.content}
                        />

                    ))
                }
            </div>
        </div>









    </>




);




const cardsData = [
    {
        id: 1, title: 'Employee Onboarding', content: '₹', money: "2",
        imgUrl: require('../../../../Components/Image/Employee Onboarding@2x.png'),
        feature1: "Employee onboarding is a crucial part for any organization, Seamless onboarding process provides positive experience to employees and can be quickly deployed to become productive and effective contributors to your organizations. Trouble free onboarding process with HRVERSE ensures positive employee experience and optimum employee engagement during various stages of employee onboarding.",
        // feature2: "the printing and typesetting ",


        feature3: "Lorem Ipsum is simply dummy text of 1",
        feature4: "the printing and typesetting ",


        feature5: "Payroll",
        feature6: "Exit Process",
        feature7: "Expenses Management",
        feature8: "Assets Management",
        feature9: "Employee Self Services",
        // "imgPath": require("../../../Components/Image/Group 653@2x.png")
    },



    {
        id: 2, title: 'Asset Management', content: '$', money: "3",
        //  imgUrl: 'https://unsplash.it/201/200',
        imgUrl: require('../../../../Components/Image/Asset Management@2x.png'),


        feature1: "With multiple departments and number of employees in each department, it is imperative to keep a track of assets allocated to each employee. HRVERSE will keep complete records of the assets assigned to respective employee along with maintenance and service required for particular asset. HRVERSE enables organizations to define type of asset allocated to employee so that these assets can be returned during the exit formalities with clear records of assets allocation.",
        feature2: "the printing and typesetting ",

        feature3: "Lorem Ipsum is simply dummy text of 1",
        feature4: "the printing and typesetting ",



        feature5: "Payroll",
        feature6: "Exit Process",
        feature7: "Expenses Management",
        feature8: "Assets Management",
        feature9: "Employee Self Services",
        link: "/home",

    },

    {
        id: 3, title: 'Expenses Management', content: '$', money: "3",
        //  imgUrl: 'https://unsplash.it/201/200',
        imgUrl: require('../../../../Components/Image/Expenses Management@2x.png'),


        feature1: "HRVERSE simplifies tracking of various expenses incurred by employees at various levels. Employees incurring expenses and submit claims for reimbursements can be settled without any hassle. Track record of the expenses incurred by each employee can be maintained and utilized for future reference. HRVERSE also provides dedicated dashboards for employee to submit their expenses, get approvals and understand the status on reimbursements.",
        feature2: "the printing and typesetting ",

        feature3: "Lorem Ipsum is simply dummy text of 1",
        feature4: "the printing and typesetting ",

        feature5: "Payroll",
        feature6: "Exit Process",
        feature7: "Expenses Management",
        feature8: "Assets Management",
        feature9: "Employee Self Services",
        link: "/home",

    },











    // { id: 3, title: 'CARD 3', content: 'Peter Parker', imgUrl: 'https://unsplash.it/200/201' },
    // { id: 4, title: 'CARD 4', content: 'Tony Stark', imgUrl: 'https://unsplash.it/201/201' },
    // { id: 5, title: 'CARD 5', content: 'Reed Richards', imgUrl: 'https://unsplash.it/202/200' },
    // { id: 6, title: 'CARD 6', content: 'Wade Wilson', imgUrl: 'https://unsplash.it/200/199' },
    // { id: 7, title: 'CARD 7', content: 'Peter Quill', imgUrl: 'https://unsplash.it/199/199' },
    // { id: 8, title: 'CARD 8', content: 'Steven Rogers', imgUrl: 'https://unsplash.it/199/200' },
    // { id: 9, title: 'CARD 9', content: 'Bruce Banner', imgUrl: 'https://unsplash.it/200/198' },
    // { id: 10, title: 'CARD 10', content: 'Vincent Strange', imgUrl: 'https://unsplash.it/198/199' },
]




export default function BasicButtons() {

    return (
        <Container >

            <div className="container-scroll-features" >
                {/* <h1 style={{ 'text-align': 'center' }}>
                React Card Slider
            </h1> */}
                <CardContainer cards={cardsData} />

                <br />
                <br />
                <br />
                <br />

            </div>
        </Container>

    );
}



