import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Customer from '../../Home/Customer/Customer';
import CustomerResponsive from '../../Home/Customer/CustomerResponsive';



import Footer from '../../Footer/Footer';
import Copyright from '../../Copyright/Copyright/Copyright';
import ReadmoreBlogcard from '../ReadmoreBlog/ReadmoreBlogcard';
import './ReadmoreBlog.css';
import blogimage from '../../../Components/Image/BlogBackground.svg';



import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Sidebar from '../../../Components/Sidebar';
import FooterMobile from '../../FooterMobile/FooterMobile';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function FullWidthGrid() {
    return (
        <>
            <Sidebar />

            <Box sx={{ flexGrow: 1 }} style={{ paddingTop: "150px" }}>
                <h1 class="test2">
                    <FacebookRoundedIcon style={{ color: "#1877F2", fontSize: "35px" }} />  <TwitterIcon style={{ color: "#03A9F4", fontSize: "35px" }} />  <LinkedInIcon style={{ color: "#007AB9", fontSize: "35px" }} />
                </h1>

                <Container>
                    <Grid container spacing={2}>



                        <Grid item xs={12} md={12} className="">



                            <section className=''>

                                <Link to="/Blogs" className='backbutton'>
                                    <button><ArrowBackIcon style={{ color: "#00ACFF", opacity: "100%" }} />Back</button>
                                </Link>


                                <h1 className='readmetop'>
                                    Selection Process In HRM: 11
                                    <br /> Important Metrics And Selection <br /> Criteria You Must Know
                                </h1>
                                <p className='readmetopparagraph'>176 views • 8 mins read</p>


                            </section>


                        </Grid>




                        <Grid item xs={12} md={12} className="readmeimage">




                            <section className='readmeimage'>

                                <img src={blogimage} className="readmeimage" />


                            </section>


                        </Grid>
                        <Grid item xs={12} md={12}>

                            <section className='paragraphblog'>


                                <div>
                                    <h1 className='blogdetailheading'>

                                        What is Lorem Ipsum?

                                    </h1>
                                    <br />

                                    <p className='subparagraphblog'>

                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br /><br /> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. .

                                    </p>

                                </div>

                            </section>




                        </Grid>

                    </Grid> </Container>
            </Box>



            <ReadmoreBlogcard />



            {/* footer */}
            <br />



            <Container className=''>
                {/* <hr className='horizontalline' /> */}
            </Container>
            <div className='display-none-form'>
                <Customer />
            </div>
            <div className='display-show'>
                <CustomerResponsive />
            </div>


            {/* <Container className=''> */}
            {/* <hr className='horizontalline2' /> */}
            <hr className='horizontallinebottom' />

            {/* </Container> */}

            {/* <Footer />
                <hr className='horizontallinebottom' />
            <Copyright /> */}

            <div className='display-none-form'>
                <Footer />
                {/* <Container className=''> */}
                <hr className='horizontallinebottom' />
                {/* </Container> */}
                <Copyright />
            </div>
            <div className='display-show'>
                <FooterMobile />
            </div>




        </>

    );
}
