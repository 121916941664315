import React from "react";
import Cardsa from '@mui/material/Card';
import Box from '@mui/material/Box';
import { Grid } from "@mui/material";
import './Scrolling.css';
import pricingcardsymboll from '../../Components/Image/Group 6330@2x.png';
import pricingsymboll from '../../Components/Image/Group 6320@2x.png';






// const Card = (props) => (
//     <div className="card">
//         <img src={props.imgUrl}
//             alt={props.alt || 'Image'} />
//         <div className="card-content">
//             <h2 style={{ color: "red" }}>{props.title}</h2>
//             <p>{props.content}</p>
//         </div>
//     </div>
// );






const Card = (props) => (

    <>
        <div className="main-card">
            {/* <div> */}
            <div style={{ width: '180px', height: '45px', backgroundColor: '#D8E6FF', margin: 'auto', textAlign: "center", borderRadius: '4px' }}>
                {/* <img style={{ marginLeft: '76%', marginTop: '10px' }} className='india-pricingcardsymbolld' width={10} src={pricingcardsymboll} /> */}
                <div style={{ paddingTop: '8px' }}>
                    <h1 className="props-title">{props.title}</h1>
                </div>

            </div>
            {/* </div> */}

            <img width={130} className='pricingsymboll' src={pricingsymboll} />

            <Cardsa sx={{}} style={{ display: "inline-flex", backgroundColor: "" }} className="pricing-card">
                <div style={{ display: 'block', margin: "auto" }}>

                    <div><h5 className="one-pricing-for">ONE PRICING FOR ALL</h5></div>

                    <div>
                        <span className="scroll-pricing-sym">{props.content}</span>
                        <span className="scroll-pricing">{props.money}</span>
                    </div>


                    <div>
                        <h1 className="per-emp">Per employee per day</h1>
                    </div>
                    <div>
                        <br />
                        <hr className='pricingline' />
                        <br />

                    </div>
                    <div style={{ display: 'flex', }}>
                        <span style={{ padding: '10px' }}>
                            <img src={pricingcardsymboll} width={15} /></span>
                        <span className="scroll-features">{props.feature1}</span>
                    </div>
                    <div style={{ display: 'flex', }}>
                        <span style={{ padding: '10px' }}>
                            <img src={pricingcardsymboll} width={15} /></span>
                        <span className="scroll-features"> {props.feature2}</span>
                    </div>                    <div style={{ display: 'flex', }}>
                        <span style={{ padding: '10px' }}>
                            <img src={pricingcardsymboll} width={15} /></span>
                        <span className="scroll-features"> {props.feature3}</span>
                    </div>                    <div style={{ display: 'flex', }}>
                        <span style={{ padding: '10px' }}>
                            <img src={pricingcardsymboll} width={15} /></span>
                        <span className="scroll-features"> {props.feature4}</span>
                    </div>                    <div style={{ display: 'flex', }}>
                        <span style={{ padding: '10px' }}>
                            <img src={pricingcardsymboll} width={15} /></span>
                        <span className="scroll-features"> {props.feature5}</span>
                    </div>                    <div style={{ display: 'flex', }}>
                        <span style={{ padding: '10px' }}>
                            <img src={pricingcardsymboll} width={15} /></span>
                        <span className="scroll-features"> {props.feature6}</span>
                    </div>                    <div style={{ display: 'flex', }}>
                        <span style={{ padding: '10px' }}>
                            <img src={pricingcardsymboll} width={15} /></span>
                        <span className="scroll-features"> {props.feature7}</span>
                    </div>                    <div style={{ display: 'flex', }}>
                        <span style={{ padding: '10px' }}>
                            <img src={pricingcardsymboll} width={15} /></span>
                        <span className="scroll-features"> {props.feature8}</span>
                    </div>                    <div style={{ display: 'flex', }}>
                        <span style={{ padding: '10px' }}>
                            <img src={pricingcardsymboll} width={15} /></span>
                        <span className="scroll-features"> {props.feature9}</span>
                    </div>

                    <br />



                    <div className='cardtopheading'>
                        {/* <Link to='/Subscription'> */}
                        <button className="btn default">Buy  Now</button>
                        {/* </Link> */}
                    </div>
                    <br />


                </div>
            </Cardsa>
        </div>
    </>

);




// const CardContainer = (props) => (
//     <div className="cards-container">
//         {
//             props.cards.map((card) => (
//                 <Card title={card.title}
//                     content={card.content}
//                     imgUrl={card.imgUrl} />
//             ))
//         }
//     </div>
// );



const CardContainer = (props) => (
    <>
        <div style={{ textAlign: 'center', }}>
            <div className="cards-container" >
                {
                    props.cards.map((card) => (
                        <Card
                            link={card.link}

                            money={card.money}

                            title={card.title}
                            feature1={card.feature1}
                            feature2={card.feature2}
                            feature3={card.feature3}
                            feature4={card.feature4}
                            feature5={card.feature5}
                            feature6={card.feature6}
                            feature7={card.feature7}
                            feature8={card.feature8}
                            feature9={card.feature9}
                            content={card.content}
                            imgUrl={card.imgUrl} />

                    ))
                }
            </div>
        </div>









    </>




);




const cardsData = [
    {
        id: 1, title: 'India', content: '₹', money: "2", imgUrl: 'https://unsplash.it/200/200',
        feature1: "Employee On-Boarding",
        feature2: "Document Management",
        feature3: "Attendance Management",
        feature4: "Leave Management",
        feature5: "Payroll",
        feature6: "Exit Process",
        feature7: "Expenses Management",
        feature8: "Assets Management",
        feature9: "Employee Self Services",
    },



    {
        id: 2, title: 'International', content: '$', money: "3", imgUrl: 'https://unsplash.it/201/200',

        feature1: "Employee On-Boarding",
        feature2: "Document Management",
        feature3: "Attendance Management",
        feature4: "Leave Management",
        feature5: "Payroll",
        feature6: "Exit Process",
        feature7: "Expenses Management",
        feature8: "Assets Management",
        feature9: "Employee Self Services",
        link: "/home",

    },











    // { id: 3, title: 'CARD 3', content: 'Peter Parker', imgUrl: 'https://unsplash.it/200/201' },
    // { id: 4, title: 'CARD 4', content: 'Tony Stark', imgUrl: 'https://unsplash.it/201/201' },
    // { id: 5, title: 'CARD 5', content: 'Reed Richards', imgUrl: 'https://unsplash.it/202/200' },
    // { id: 6, title: 'CARD 6', content: 'Wade Wilson', imgUrl: 'https://unsplash.it/200/199' },
    // { id: 7, title: 'CARD 7', content: 'Peter Quill', imgUrl: 'https://unsplash.it/199/199' },
    // { id: 8, title: 'CARD 8', content: 'Steven Rogers', imgUrl: 'https://unsplash.it/199/200' },
    // { id: 9, title: 'CARD 9', content: 'Bruce Banner', imgUrl: 'https://unsplash.it/200/198' },
    // { id: 10, title: 'CARD 10', content: 'Vincent Strange', imgUrl: 'https://unsplash.it/198/199' },
]




export default function BasicButtons() {

    return (
        <div className="container-scroll" >
            {/* <h1 style={{ 'text-align': 'center' }}>
                React Card Slider
            </h1> */}
            <CardContainer cards={cardsData} />


        </div>
    );
}



