import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import './Pricing.css';
import Pricingcard from './Pricingcard/Pricingcard';
import Container from '@mui/material/Container';
import Customer from '../Home/Customer/Customer';
import CustomerResponsive from '../Home/Customer/CustomerResponsive';
import ScrollingPriceCard from "../ScrollingPriceCard/ScrollingPriceCard";
import Footer from '../Footer/Footer';
import Copyright from '../Copyright/Copyright/Copyright';
import Sidebar from '../../Components/Sidebar';
import CalculateYourPlan from './CalculateYourPlan/CalculateYourPlan';
import CalculatePlan from './CalculateYourPlan/CalculatePlan';
import FooterMobile from '../FooterMobile/FooterMobile';





const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function FullWidthGrid() {
    return (<>

        <Sidebar />
        <Box sx={{ flexGrow: 1 }} style={{ paddingTop: "150px" }}>
            <Grid container spacing={2}
                // direction="column-reverse"
                justifyContent="center"
                alignItems="center">
                <h1 style={{ paddingLeft: "20px" }}><span className='pricingheading'>LubriChem </span> <span className='pricingheadingsecond'></span></h1>


                {/* <br /> */}
                {/* <p className='pricingparagraph'> Powerful, comprehensive & yet the most affordable HR software you can buy without <br />breaking your bank.</p> */}

            </Grid>
            <p className='pricingparagraph'>Tribology Redefined </p>
        </Box>

        {/* <Pricingcard /> */}
        <section className=''>
                        <div>
                            <h1 className='textaligncenter'>Be a part of our organization, where you can shape your own Future & Shine</h1>
                            <p className='textsizeparagraph'>

                                Send Your Resume : - arp@lubrichem.in
                            </p>
                            {/* <p className='textsizeparagraph'>  In publishing and graphic </p> */}

                        </div>
                    </section>
                    <br></br>
                    <hr className='horizontallinebottom' />
                {/* </Container> */}
                <div className='display-none-form'>
                    <Footer />
                    {/* <Container className=''> */}
                    <hr className='horizontallinebottom' />
                    {/* </Container> */}
                    <Copyright />
                </div>
                <div className='display-show'>
                    <FooterMobile />
                </div>
        

    </>
    


    );
    
    
}

