import React from "react";
import "./CalculateYourPlan.css";
import pricingcardsymboll from '../../../Components/Image/Group 6330@2x.png';


class App extends React.Component {
    constructor() {
        super();
        this.state = {
            price: 0,
            discount: 0,
            total: 0,
            finalPrice: 0,
            gst: 0,
            onboarding: 0,
            baseprice: 0,
            totalpaid: 0,
            addgst: 0,
            onetime: 0,



        }
        this.handleDiscount = this.handleDiscount.bind(this);
    }

    /* update values on change */
    handleDiscount(event) {
        this.setState({
            [event.target.name]: event.target.value
        },
            () => {
                this.setState({
                    total: this.totalDiscount(),
                    finalPrice: this.getFinalPrice(),
                    gst: this.getgst(),
                    onboarding: this.getonboarding(),
                    baseprice: this.getbaseprice(),
                    totalpaid: this.gettotalpaid(),
                    addgst: this.getaddgst(),
                    onetime: this.getonetime(),

                })
            });
    }

    /* calculate and return total discount */
    totalDiscount() {
        return (Math.abs((((1 - (this.state.discount / 100))) - 1) * 100)).toFixed(2);
    }

    getFinalPrice() {
        return (this.state.price - (this.state.price * this.totalDiscount() / 100)).toFixed(2);
    }


    getbaseprice() {
        return (this.state.price * 2 * 12).toFixed(2);
    }

    getgst() {
        // return (this.state.price - (this.state.price * (100 / (100 + 18)))).toFixed(2);
        // return (((this.state.price * 18) / 100).toFixed(2));
        return (((this.getaddgst() * 18) / 100).toFixed(2));






    }





    // getgst() {
    //     return (Math.abs(this.gettotalpaid() - (this.gettotalpaid() * (100 / (100 + 18))))).toFixed(2);
    // }


    getonboarding() {

        return (Math.abs(this.state.price * 100)).toFixed(2);

    }


    getaddgst() {
        return (Math.abs((+this.getbaseprice()) + (+this.getonboarding()))).toFixed(2);
    }


    getonetime() {
        return (Math.abs((+this.getonboarding()) + (+this.getgst()))).toFixed(2);
    }



    gettotalpaid() {

        // return (this.state.price + this.getonboarding).toFixed(2);
        // return (Math.abs((+this.state.price) + (+this.getonboarding() + (+this.getgst())))).toFixed(2);
        return (Math.abs((+this.getbaseprice()) + (+this.getonboarding() + (+this.getgst())))).toFixed(2);


    }


    // getgst() {
    // return (Math.abs(this.gettotalpaid() - (this.gettotalpaid() * (100 / (100 + 18))))).toFixed(2);


    // return ((this.gettotalpaid()) - ((this.gettotalpaid()) * ((100 / (100 + 18))))).toFixed(2);

    // return (this.state.totalpaid - (this.state.totalpaid * (100 / (100 + 18)))).toFixed(2);


    // }


    // totalDiscount() {
    //     return (Math.abs((((1 - (this.state.discount / 100))) - 1) * 100)).toFixed(2);
    // }



    render() {
        return (
            <>
                <div className="App">
                    <form>



                        {/* <h1>Create Product App</h1>
                    <label>Product Name:</label>
                    <input
                        type="text"
                        name="product name"
                        className="data"
                    />
                    <label>Product Description:</label>
                    <input
                        type="text"
                        name="description"
                        className="data"
                    /> */}



                        <label>  Employee:</label>
                        <input
                            name="price"
                            value={this.state.price}
                            onChange={this.handleDiscount}
                            type="text"
                            className="data"
                        />
                        <label>Product discount:</label>
                        <input
                            name="discount"
                            value={this.state.discount1}
                            onChange={this.handleDiscount}
                            type="text"
                            className="data"
                        />



                        <label>Discounted Total Price:</label>
                        <input
                            type="text"
                            value={this.state.finalPrice}
                            className="data"
                        />



                        <label>Base price  :</label>
                        <input
                            type="text"
                            value={this.state.baseprice}
                            className="data"
                        />







                        <label>Onboarding fee  :</label>

                        <input
                            type="text"
                            value={this.state.onboarding}
                            className="data"
                        />

                        <label>GST  :</label>

                        <input
                            type="text"
                            value={this.state.gst}

                            className="data"
                        />



                        <label>totalpaid   :</label>

                        <input
                            type="text"
                            value={this.state.totalpaid}
                            className="data"
                            placeholder="hell"
                        />


                        {/* <label>addgst   :</label>

                    <input
                        type="text"
                        value={this.state.addgst}
                        className="data"
                    /> */}







                        {/* 
                    <label>Product In Stock:</label>
                    <select className="data">
                        <option className="data">Select</option>
                        <option className="data">y</option>
                        <option className="data">m</option>
                    </select> */}




                        {/* <button type="submit" className="button--style" value="submit" name="product">
                        Submit
                    </button>
                    <button type="submit" className="button--style" value="submit" name="product">
                        Clear
                    </button> */}

                        <h1> {this.state.price}
                        </h1>


                    </form>





                </div>


                <h1>

                    {this.state.onetime}


                </h1>


            </>

        );
    }
}

export default App;
