import React from "react";
import Counter from "./count";
import "./Counter.css";

export default function App() {
    return (
        <div className="">
            <div className="photo-profile">{/* <img  /> */}</div>
            <div className="numbers">
                {/* <Counter number={105} title="Posts" /> */}

                <Counter number={2400} title="" />


                <h1 style={{ marginTop: "70px", marginLeft: "-130px" }} className="customer-2 display-none-form">Customers</h1>
                {/* <Counter number={5175} title="Followers" /> */}


                {/* <Counter number={468} title="Following" /> */}
            </div>
        </div>
    );
}
