import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import './Positionfix.css';

import Grid from '@mui/material/Grid';
import pricingcardsymboll from '../../Components/Image/sidecutimage.png';
import pricingcardsymbolltwo from '../../Components/Image/Group 6330@2x.png';
import Dialogform from '../../Pages/Home/Dialog/Dialogform';
import { Link } from 'react-router-dom';
import { color } from '@mui/system';



export default function FixedContainer() {
    return (
        <React.Fragment>
            <CssBaseline />
            <Container >
                <div className='container-experience'>
                    <Box sx={{ bgcolor: '#e7f3fe', height: '300px', borderRadius: "10px", margin: "-2%" }} >

                        <img src={pricingcardsymboll} style={{ maxWidth: "100%", height: "130px", objectFit: "contain" }} />

                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={5} md={6} style={{ textAlign: "center", marginTop: "-70px" }}>

                                    <div className='fix_experince' style={{ position: "absolute", paddingTop: "30px" }}>
                                        <img src={pricingcardsymbolltwo} style={{ maxWidth: "100%", height: "10px", objectFit: "contain", paddingLeft: "95%" }} />
                                        <h1 className='mob-start exerience-font'  > Enquiry for Free</h1>
                                        {/* <Button style={{ width: "200px" }} variant="contained"><Dialogform />  Book Live Demo</Button> */}
                                        <br className='display-none' />
                                        {/* <h6 style={{ marginTop: "10%", marginRight: "100px" }} className=""> <Dialogform /> </h6> */}
                                        <h6 className="fixed_booklive"> <Dialogform /> </h6>


                                    </div>


                                </Grid>
                                <Grid item xs={12} md={1} sm={2} style={{ textAlign: "center", position: "relative" }}  >


                                    <div style={{ textAlign: "center", marginTop: "-60px" }} className="vl">


                                    </div>

                                </Grid>
                                {/* <Grid item xs={12} md={5} sm={5} style={{ textAlign: "center", color: "#606062" }}>


                                    <h1 className='mob-resp'><span className='mob-already' >Already a Member ? </span > <a href="http://lubrichem.co.in/" style={{
                                        color: "#1C6BFE"
                                    }}> Sign in  </a>

                                    </h1>

                                </Grid> */}

                            </Grid>
                        </Box>
                    </Box>
                </div>

            </Container>
        </React.Fragment>
    );
}






