import { padding } from "@mui/system";
import React from "react";
import Startupimage from "../../../Components/Image/startupbanner.svg"
import "./StartupBanner.css";
import Dialogform from '../../Home/Dialog/Dialogform';



function App() {
    return (
        <>
            <img src={Startupimage} style={{ position: "absolute", objectFit: "cover" }} className="startupimage" />
            <div className="monssss" style={{ backgroundColor: "", position: "absolute", margin: "6%", marginLeft: "12%", textAlign: "left" }}>
                <h1 className="first-demo-top">Speciality Lubricants <span className="small-font-size"> & </span></h1><br></br>
                <h1 className="first-demo-top" style={{ paddingLeft: '20%' }}>Metal Working  </h1><br></br>
                <h1 className="first-demo-top" style={{ paddingLeft: '20%' }}>  Lubricants </h1>
                <h1 style={{ paddingLeft: "15%" }} className="sub-paragraph-st">  Robust Formulations & Cost Effective Products </h1>
                <br className="break-startup" />
                <div className="dialoag-pop-form">
                    <Dialogform />
                </div>
            </div>
        </>
    );
}

export default App;