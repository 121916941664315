import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import './Recruitment.css';
// import EmployeeeManagementimage from '../../../Components/Image/ils_05@2x.png'
// import PolicyDocumentsimage from '../../../Components/Image/ils_05.png';
import PolicyDocumentsimage from '../../../Components/Image/ils_05@2x.png';
import Servicescard from '../../Services/Servicescard/Servicescard';
import Startforfree from '../../Home/Startforfree/Startforfree';
import Customer from "../../Home/Customer/Customer";
import CustomerResponsive from "../../Home/Customer/CustomerResponsive";
import Featurestwo from './Featurestwo/Featurestwo';
// ########### imag svg Payroll 
import EmployeeeManagementimage from '../../../Components/Image/water.jpeg';
import Payrollimg from '../../../Components/Image/Salary & Payroll (1).svg';
import LeaveImage from '../../../Components/Image/Onboarding.svg';




import Footer from '../../Footer/Footer';
import Copyright from '../../Copyright/Copyright/Copyright';
import Sidebar from '../../../Components/Sidebar';
import Symboll from '../../../Components/Image/Group 611@2x.png';
import Attendancemana from "./Attendancemana";
import Positionfix from '../../../Demopages/positionfix/Positionfix';
import FooterMobile from '../../FooterMobile/FooterMobile';
import FeaturesCardScroll from '../../../Pages/Services/Servicescard/FeaturesCardScroll/FeaturesCardScroll';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function FullWidthGrid() {
    return (
        <>
            <Sidebar />
            <div style={{ paddingTop: "130px" }} id="first_feature">

                <Container style={{ paddingTop: "25px" }} >
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2} className="reverse-boxes">

                            <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
                                <section>
                                    <div>
                                        {/* <p className='hiringparagraph'>The complete attendance management system</p> */}
                                        <h1 className='hiringboldtop'>Water soluble & Semi-Synthetic Metal working Fluids  </h1>
                                        <p className='hiringparagraph'> Our company is facilitated with very progressive machinery and technologies that help us in meeting urgent and huge order of our patrons in a timely way.</p>
                                    </div></section>
                            </Grid>
                            <Grid item xs={12} md={6} sm={6} lg={6} xl={6} className='revarse-grid'>
                                {/* <Item> */}

                                <br />
                                {/* <img width={300} height="auto" src={Symboll} className="symboll-img-recuriment" /> */}


                                <img src={EmployeeeManagementimage} alt="React Logo" style={{ width: "100%", height: "100%", objectFit: "contain" }} />


                                {/* </Item> */}
                            </Grid>

                        </Grid>
                        <br></br>
                        <section>
                            <div style={{ margin: "auto", textAlign: "center", alignItems: "center", fontSize: "38px", fontFamily: "lato", color: "#1C6BFE" }}>
                                <h1 style={{ fontWeight: "bold" }} className="empowering-style">FINE KUT 40</h1>
                                <p className='startup-paragraph' style={{ fontSize: "", fontFamily: "lato", color: "#707070" }}>
                                A premium quality emulsificable cutting oil suitable for all types of machining operations to offer excellent cooling and tool life. Special additives and rust inhibitors are added to ensure excellent performance and to minimize bacterial growth in the emulsion. Diluted in water at concentration ranging between 3 to 10 %, Provides excellent cooling ,mild lubricity, rust prevention. Performs upto 250 ppm.</p>
                            </div>
                            <br />
                         </section>
                         <section>
                            <div style={{ margin: "auto", textAlign: "center", alignItems: "center", fontSize: "38px", fontFamily: "lato", color: "#1C6BFE" }}>
                                <h1 style={{ fontWeight: "bold" }} className="empowering-style">FINE KUT SS 4000</h1>
                                <p className='startup-paragraph' style={{ fontSize: "", fontFamily: "lato", color: "#707070" }}>
                                A semi synthetic cutting fluid designed to give more longer emulsion life than normal soluble cutting fluid.It mixes readily with water to provide excellent lubrication and corossion protection.It can also be used for machining of aluminum and alloys.It has better operational performance and is operator friendly.</p>
                            </div>
                            <br />
                         </section>
                         <section>
                            <div style={{ margin: "auto", textAlign: "center", alignItems: "center", fontSize: "38px", fontFamily: "lato", color: "#1C6BFE" }}>
                                <h1 style={{ fontWeight: "bold" }} className="empowering-style">FINE KUT HW</h1>
                                <p className='startup-paragraph' style={{ fontSize: "", fontFamily: "lato", color: "#707070" }}>
                                A premium quality water soluble cutting oil suitable for heavy machining operations offering excellent tool life & Sump life.This Cutting oil is formulated to achieve high performance in hard water upto 650 to 700 ppm.</p>
                            </div>
                            <br />
                         </section>
                         <section>
                            <div style={{ margin: "auto", textAlign: "center", alignItems: "center", fontSize: "38px", fontFamily: "lato", color: "#1C6BFE" }}>
                                <h1 style={{ fontWeight: "bold" }} className="empowering-style">FINE KUT 6000</h1>
                                <p className='startup-paragraph' style={{ fontSize: "", fontFamily: "lato", color: "#707070" }}>
                                This is a super heavy duty water soluble cutting oil designed to achieve the best performance in hard water upto 750 PPM. It is suitable for all metals and all types of machining operations .</p>
                            </div>
                            <br />
                         </section>
                         <section>
                            <div style={{ margin: "auto", textAlign: "center", alignItems: "center", fontSize: "38px", fontFamily: "lato", color: "#1C6BFE" }}>
                                <h1 style={{ fontWeight: "bold" }} className="empowering-style">SYNTHOFINE 70</h1>
                                <p className='startup-paragraph' style={{ fontSize: "", fontFamily: "lato", color: "#707070" }}>
                                SYNTHOFINE 70 is a fully synthetic cutting oil developed specially for high speed fine and surface grinding. It can be used by diluting 3-5% in Soft water.</p>
                            </div>
                            <br />
                         </section>
                         <section>
                            <div style={{ margin: "auto", textAlign: "center", alignItems: "center", fontSize: "38px", fontFamily: "lato", color: "#1C6BFE" }}>
                                <h1 style={{ fontWeight: "bold" }} className="empowering-style">FINE KUT SS 8000</h1>
                                <p className='startup-paragraph' style={{ fontSize: "", fontFamily: "lato", color: "#707070" }}>
                                This is a robust semi synthetic fluid for multi metal operations for new age advanced machines.No foaming at high pressures & performs up to 1000 PPM water hardness.</p>
                            </div>
                            <br />
                         </section>
                    </Box></Container>
                

                <Attendancemana />

                <br />



                {/* <Container className=''>
                    <hr className='horizontalline' />

                </Container> */}


                {/* <div className='display-none-form'>

                    <Customer />
                </div>
                <div className='display-show'>
                    <CustomerResponsive />
                </div> */}





                {/* <Container className=''> */}
                {/* <hr className='horizontalline2' /> */}
                <hr className='horizontallinebottom' />
                {/* </Container> */}
                <div className='display-none-form'>
                    <Footer />
                    {/* <Container className=''> */}
                    <hr className='horizontallinebottom' />
                    {/* </Container> */}
                    <Copyright />
                </div>
                <div className='display-show'>
                    <FooterMobile />
                </div>


            </div>
        </>

    );
}
