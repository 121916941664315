import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
// import './BookLive.css';
import Button from '@mui/material/Button';
import { useForm } from "react-hook-form";



export default function FullWidthGrid() {


    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const onSubmit = data => console.log(data);

    console.log(watch("example")); // watch input value by passing the name of it





    return (

        <Container>

            <Box sx={{ flexGrow: 1, }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1} >

                        <Grid item xs={12} md={12}>


                            {/* <h1 className='formheading' >Book Live Demo</h1> */}
                            <br />
                            <fieldset>
                                <legend>Company Name </legend>
                                {/* <label for="fname">First name:</label> */}
                                <input className='inputfeild' type="text" id="fname" name="fname" placeholder='Enter Company Name' {...register("exampleRequired", { required: true })} />


                            </fieldset>
                            {errors.exampleRequired && <span className='errorfeild'>Company Name required</span>}



                        </Grid>
                        <Grid item xs={12} md={6}>


                            <fieldset>
                                <legend>Employee Count</legend>
                                {/* <label for="fname">First name:</label> */}
                                <input className='inputfeild' type="text" id="fname" name="fname" placeholder='Enter number of employee' />


                            </fieldset>
                            {errors.exampleRequired && <span className='errorfeild'>Employee  Count required</span>}



                        </Grid>
                        <Grid item xs={12} md={6}>


                            <fieldset>
                                <legend> Name</legend>
                                {/* <label for="fname">First name:</label> */}
                                <input className='inputfeild' type="text" id="fname" name="fname" placeholder='Enter User Name' />


                            </fieldset>
                            {errors.exampleRequired && <span className='errorfeild'>Name required</span>}



                        </Grid>
                        <Grid item xs={12} md={12}>


                            <fieldset>
                                <legend>Email </legend>
                                {/* <label for="fname">First name:</label> */}
                                <input className='inputfeild' type="text" id="fname" name="fname" placeholder='Enter Email' />


                            </fieldset>
                            {errors.exampleRequired && <span className='errorfeild'> Email required</span>}



                        </Grid>
                        <Grid item xs={12} md={6}>


                            <fieldset>
                                <legend>Contact Number </legend>
                                {/* <label for="fname">First name:</label> */}
                                <input className='inputfeild' type="text" id="fname" name="fname" placeholder='Enter Contact number ' />


                            </fieldset>
                            {errors.exampleRequired && <span className='errorfeild'> Contact Number required</span>}



                        </Grid>

                        <Grid item xs={12} md={6}>


                            <fieldset>
                                <legend>PAN / TAN of your organization </legend>
                                {/* <label for="fname">First name:</label> */}
                                <input className='inputfeild' required type="text" id="fname" name="fname" placeholder=' Enter PAN / TAN of' />


                            </fieldset>



                        </Grid>
                        <Grid item xs={12} md={12}>

                            <button type='submit' className='formbutton' fullWidth variant="contained">Sign up</button>

                            {/* <br /> */}

                        </Grid>

                    </Grid>
                </form>


            </Box>

        </Container >

    );
}
