import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import './Copyright.css';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function FullWidthGrid() {
    return (
        <>
            <Container>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2} style={{ padding: "20px" }}>
                        <Grid item sm={6} lg={6} xl={6} xs={12} md={6} className=" ">
                            <h1 className='copyrightleft'>  Copyright ©2023 Lubrichem made with 🤍 in India.</h1>
                        </Grid>
                        <Grid item sm={6} lg={6} xl={6} xs={12} md={6}>
                            <h1 className='copyrightright'> Privacy Policy | Terms of Service </h1>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </>
    );
}










