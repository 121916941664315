import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import NutShell from '../Pages/Home/NutShell/NutShell';
import './Nutshellscroll.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import StepContent from '@mui/material/StepContent';
// or


function getSteps() {

    return [<b style={{ color: 'purple' }}>'Enter Personal Details'</b>,
    <b style={{ color: 'purple' }}>'Enter Education Details'</b>,
    <b style={{ color: 'purple' }}>'Enter Address'</b>];



}

function getStepContent(step: number) {
    switch (step) {
        case 0:
            return (

                <NutShell />

            );
        case 1:
            return (
                <NutShell />
            );
        case 2:
            return (
                <NutShell />
            );
        default:
            return 'Unknown step';
    }
}

export default function GeekStepper() {
    // const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <div className='nutshellstep'>
            {/* <h1>GeeksforGeeks Stepper Form</h1> */}
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                    <Step>
                        {/* <StepLabel>{label}</StepLabel> */}
                        <StepContent>
                            <Typography>{getStepContent(index)}</Typography>
                            <div >
                                <div>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}

                                    >
                                        {/* Back */}
                                        <ArrowBackIcon />
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}


                                    >
                                        <ArrowBackIcon />

                                        {activeStep === steps.length - 1 ? '' : 'Next'}
                                    </Button>
                                </div>
                            </div>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>


            {activeStep === steps.length && (
                <Paper square elevation={0}
                >
                    {/* <Typography>Form is submitted</Typography> */}

                    <NutShell />

                    <Button onClick={handleBack}
                    >
                        {/* Reset */}
                        <ArrowBackIcon />
                    </Button>
                    <Button onClick={handleReset}
                    >
                        {/* Reset */}
                        <ArrowForwardIcon />
                    </Button>
                </Paper>
            )}



        </div>
    );
}
