import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';



// import EmployeeeManagementimage from '../../../../Components/Image/ils_05@2x.png'
import ExitProcess from '../../../../Components/Image/Exit Process.svg'
import SelfService from '../../../../Components/Image/Self Service.svg'
import DocumentsManagement from '../../../../Components/Image/Documents Management@2x.png'





const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function BasicGrid() {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Container>
                <Grid container spacing={2} className="reverse-boxes">

                    <Grid item xs={12} md={6} sm={6} lg={6} xl={6} className='revarse-grid'>
                        <div style={{ textAlign: "left" }}>
                            <h1 className='hiringboldtop'>Employee Document Management</h1>
                            <p className='hiringparagraph' style={{ textAlign: "justify" }}>It is essential to manage employee documents in organized and safe manner. HRVERSE enables Easy, simple, secured and paperless document management for each employee. Employee Documents can also be categorized and stored for entire employee life cycle such as Pre-Onboarding Documents, Onboarding Documents, Ongoing Documents and Exit documents which can be viewed for future reference as per the requirement.</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6} lg={6} xl={6} className='revarse-grid'>
                        <br />
                        <br />

                        <img src={DocumentsManagement} alt="React Logo" style={{ width: "100%", height: "auto", objectFit: "cover", }} />
                    </Grid>

                    <Grid item xs={12} md={6} sm={6} lg={6} xl={6} className='revarse-grid'>
                        <br />
                        <br />
                        <img src={SelfService} alt="React Logo" style={{ width: "100%", height: "auto", objectFit: "contain" }} />
                    </Grid>
                    <Grid item xs={12} md={6} sm={6} lg={6} xl={6} className='revarse-grid'>
                        <div style={{ textAlign: "left" }}>
                            <h1 className='hiringboldtop'>Employee Self Service</h1>
                            <p className='hiringparagraph' style={{ textAlign: "justify" }}>HRVERSE aims at making employees self-sufficient by providing interactive dashboards to avoid waste of efforts and time. Employees can access their dedicated dashboards to apply for leaves, short leaves, half days and seek hierarchy wise approvals on the dashboards. Simultaneously employees can instantly download salary slips, view profile, view yearly leave calendar, leave records, balance leaves, encashment if any along with assets assigned.</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6} lg={6} xl={6} className='revarse-grid'>
                        <div style={{ textAlign: "left" }}>
                            <h1 className='hiringboldtop'>Exit Process</h1>
                            <p className='hiringparagraph' style={{ textAlign: "justify" }}>Smooth and transparent exit process with HRVERSE helps organizations to maintain their goodwill and brand value. HRVERSE offers seamless digitized exit formalities right from employee resignation to full & final settlements. Once Employees resign, the complete process is automated enabling resignation approvals, task handover, asset return & exit interviews. Organizations can also provide relevant documents to employee such as relieving letter, experience letter, no due letter along with limiting the access. HR Department can holistically refer to the entire data and insights for the exit employees. </p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6} lg={6} xl={6} className='revarse-grid'>
                        <br />
                        <br />
                        <img src={ExitProcess} alt="React Logo" style={{ width: "100%", height: "auto", objectFit: "contain" }} />
                    </Grid>
                </Grid>

            </Container>
        </Box>
    );
}
